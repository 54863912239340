import React from 'react';
import ReactModal from 'react-modal';

import FormButton from './FormButton';

export default function DeleteModal(props) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onRequestClose={props.hideMethod}
      style={{
        overlay: { backgroundColor: '#0005' },
        content: {
          height: '250px',
          width: '520px',
          margin: 'auto',
          border: '2px solid #000c',
          backgroundColor: '#f6af1c',
        },
      }}
    >
      <div className='flex justify-between text-2xl font-bold'>
        <h2>Cuidado!</h2>
        <button className='font-bold text-gray-600' onClick={props.hideMethod}>
          X
        </button>
      </div>
      <div className='flex justify-center h-10 mt-12 text-2xl font-bolder'>
        <h3>
          Esta ação é <u>irreversível</u>!
        </h3>
      </div>
      <div className='flex justify-end mt-10'>
        <FormButton click={props.hideMethod} color='red-500' width='w-32' text='Cancelar' icon='ban' />
        <FormButton click={props.delMethod} color='green-500' width='w-28' text='Deletar' icon='check' />
      </div>
    </ReactModal>
  );
}
