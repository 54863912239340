import axios from 'axios';
import React from 'react';

import { getToken } from '../utils/token';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchModelsTable from '../components/SearchModelsTable';

export default class ModelsPage extends React.Component {
  constructor(props) {
    super(props);

    this.searchText = '';

    this.state = {
      models: [],
      components: [],
      loading: false,
      dataLoaded1: false,
      dataLoaded2: false,
      componentsLists: {},
    };
  }

  componentDidMount() {
    this.getModels(true);
    this.getComponents();
    this.getComponentsLists();
  }

  handleTextInput = (e) => {
    this.searchText = e.target.value.toUpperCase();
    this.startPreRequestTimeout();
  };

  startPreRequestTimeout = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.getModels.bind(this), 1000);
  };

  async getModels(loading = true) {
    if (loading) {
      this.setState({ loading: true });
    }
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/models/?name=${this.searchText}`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    if (loading) {
      this.setState({ models: response.data, loading: false });
    } else {
      this.setState({ models: response.data });
    }
  }

  async getComponentsLists() {
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/components-lists/`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);

    let data = {};
    response.data.forEach((element) => {
      if (element.model) data[element.model] = element;
    });

    this.setState({
      dataLoaded1: true,
      componentsLists: data,
    });
  }

  async getComponents() {
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/components/`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    response.data.forEach((element) => {
      element.N = 0;
    });
    this.setState({
      dataLoaded2: true,
      components: response.data,
    });
  }

  render() {
    return (
      <div className='inline-block h-screen overflow-x-hidden overflow-y-scroll w-full bg-secondary'>
        <nav className='w-full h-20 flex sticky top-0 items-center justify-center bg-secondary'>
          <div className='h-10 mr-10 border rounded border-gray-300 bg-gray-200'>
            <span className='h-full mx-4'>Descrição</span>
            <input
              type='text'
              className='h-full w-52 px-4 uppercase outline-none focus:ring-2 focus:ring-tertiary'
              autoFocus
              onInput={this.handleTextInput}
            />
            <FontAwesomeIcon icon='search' className='mx-3 text-blue-600' />
          </div>
        </nav>
        <SearchModelsTable
          compound={false}
          models={this.state.models}
          loading={this.state.loading}
          components={this.state.components}
          componentsLists={this.state.componentsLists}
          headerWords={['Código', 'Nome', 'NCM', 'Categoria', 'Valor', 'Componentes']}
          dataLoaded={this.state.dataLoaded1 && this.state.dataLoaded2}
        />
      </div>
    );
  }
}
