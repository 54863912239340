import copyObject from '../utils/copyObject';

import { SyncLoader } from 'react-spinners';
import { formatFractionalValue } from '../utils/formatValues';

import SearchComponentsTableRow from './SearchComponentsTableRow';
import SearchCompoundComponentsTableRow from './SearchCompoundComponentsTableRow';

export default function SearchComponentsTable(props) {
  const headStyle = 'pl-2';

  let total_value = 0;
  props.components.map((component) => (total_value += component.value * component.amount));

  return (
    <table className='w-full bg-primary'>
      <thead className='text-white h-10'>
        <tr className='text-left'>
          {props.headerWords.map((word, index) => (
            <th key={index} className={headStyle}>
              {word}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!props.loading &&
          !props.compound &&
          props.components.map((cmp, index) => (
            <SearchComponentsTableRow
              key={cmp.id}
              component={cmp}
              rowNumber={index + 1}
              updateMethod={props.updateMethod}
              displayDeleteModal={props.displayDeleteModal}
            />
          ))}
        {!props.loading &&
          props.compound &&
          props.dataLoaded &&
          props.components.map((cmp, index) => (
            <SearchCompoundComponentsTableRow
              key={cmp.id}
              component={cmp}
              rowNumber={index + 1}
              updateMethod={props.updateMethod}
              simpleComponents={props.simpleComponents ? copyObject(props.simpleComponents) : props.simpleComponents}
              componentsList={
                props.componentsLists[cmp.id]
                  ? copyObject(props.componentsLists[cmp.id])
                  : props.componentsLists[cmp.id]
              }
              displayDeleteModal={props.displayDeleteModal}
            />
          ))}
        {(props.loading || !props.dataLoaded) && (
          <tr>
            <td colSpan={props.headerWords.length} className='bg-secondary'>
              <div className='h-screen flex justify-center mt-80'>
                <SyncLoader loading={true} color={'#000'} />
              </div>
            </td>
          </tr>
        )}
      </tbody>
      <thead className='text-white text-base h-7'>
        <tr className='text-left'>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th className={headStyle}>Total: R$ {formatFractionalValue(String(total_value))}</th>
        </tr>
      </thead>
    </table>
  );
}
