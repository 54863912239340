import axios from 'axios';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getToken } from '../utils/token';

import DeleteModal from '../components/DeleteModal';
import CircleButton from '../components/CircleButton';
import SearchComponentsTable from '../components/SearchComponentsTable';
import NewCompoundComponentModal from '../components/NewCompoundComponentModal';

export default class SearchCompoundComponentsPage extends React.Component {
  constructor(props) {
    super(props);

    this.searchText = '';

    this.state = {
      components: [],
      componentsLists: {},
      simpleComponents: [],
      loading: false,
      dataLoaded: false,
      missingComponents: false,
      displayDeleteModal: false,
      displayNewComponentModal: false,
    };
  }

  componentDidMount() {
    this.getComponents(true);
    this.getSimpleComponents();
    this.getComponentsLists();
  }

  displayNewComponentModal = () => {
    this.setState({ displayNewComponentModal: true });
  };

  hideNewComponentModal = () => {
    this.setState({ displayNewComponentModal: false });
  };

  displayDeleteModal = (id) => {
    this.componentToDelete = id;
    this.setState({ displayDeleteModal: true });
  };

  hideDeleteModal = () => {
    this.setState({ displayDeleteModal: false });
  };

  handleTextInput = (e) => {
    this.searchText = e.target.value.toUpperCase();
    this.startPreRequestTimeout();
  };

  handleCheckboxChanges = (e) => {
    const checkbox = e.target;
    this.setState({ missingComponents: checkbox.checked });
  };

  startPreRequestTimeout = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.getComponents.bind(this), 1000);
  };

  async getComponents(loading = true) {
    if (loading) {
      this.setState({ loading: true });
    }
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/components/?its_compound=1&description=${this.searchText}`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    if (loading) {
      this.setState({ components: response.data, loading: false });
    } else {
      this.setState({ components: response.data });
    }
  }

  async getSimpleComponents() {
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/components/?its_compound=0`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    response.data.forEach((element) => {
      element.N = 0;
    });
    this.setState({
      simpleComponents: response.data,
    });
  }

  async getComponentsLists() {
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/components-lists/`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);

    let data = {};
    response.data.forEach((element) => {
      if (element.component) data[element.component] = element;
    });

    this.setState({
      dataLoaded: true,
      componentsLists: data,
    });
  }

  async updateComponent(id, field, value) {
    const payload = {};
    payload[field] = value;
    axios
      .patch(`${process.env.REACT_APP_API_URL}/components/${id}/`, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        this.getComponents(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async deleteComponent() {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/components/${this.componentToDelete}/`, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        this.hideDeleteModal();
        this.getComponents();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    let componetsToRender = this.state.components;
    if (this.state.missingComponents) {
      componetsToRender = componetsToRender.filter((cmp) => {
        return cmp.min_amount > cmp.amount;
      });
    }

    return (
      <div className='inline-block h-screen overflow-x-hidden overflow-y-scroll w-full bg-secondary'>
        <NewCompoundComponentModal
          isOpen={this.state.displayNewComponentModal}
          hideMethod={this.hideNewComponentModal}
        />

        <DeleteModal
          delMethod={this.deleteComponent.bind(this)}
          isOpen={this.state.displayDeleteModal}
          hideMethod={this.hideDeleteModal}
        />
        <nav className='w-full h-20 flex sticky top-0 items-center justify-center bg-secondary'>
          <CircleButton
            size='12'
            color='blue-500'
            icon='plus'
            custom_style='mr-10'
            click={this.displayNewComponentModal}
          />
          <div className='h-10 mr-10 border rounded border-gray-300 bg-gray-200'>
            <span className='h-full mx-4'>Descrição</span>
            <input
              type='text'
              className='h-full w-52 px-4 uppercase outline-none focus:ring-2 focus:ring-tertiary'
              autoFocus
              onInput={this.handleTextInput}
            />
            <FontAwesomeIcon icon='search' className='mx-3 text-blue-600' />
          </div>
          <div className='h-10 flex items-center'>
            <input type='checkbox' id='limit_results' className='w-5 h-5 mr-2' onChange={this.handleCheckboxChanges} />
            <label htmlFor='limit_results'>Apenas componentes em falta</label>
          </div>
        </nav>
        <SearchComponentsTable
          compound={true}
          loading={this.state.loading}
          components={componetsToRender}
          dataLoaded={this.state.dataLoaded}
          componentsLists={this.state.componentsLists}
          simpleComponents={this.state.simpleComponents}
          updateMethod={this.updateComponent.bind(this)}
          displayDeleteModal={this.displayDeleteModal.bind(this)}
          headerWords={['Código', 'Descrição', 'NCM', 'Unidade', 'Quantidade mínima', 'Quantidade', 'Valor', '', '', '']}
        />
      </div>
    );
  }
}
