import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CircleButton(props) {
  return (
    <div
      className={`w-${props.size} h-${props.size} bg-${props.color} ${props.custom_style} flex justify-center items-center rounded-full cursor-pointer duration-300 transform hover:scale-105`}
      onClick={props.click}
    >
      <FontAwesomeIcon icon={`${props.icon}`} className='text-xl text-white' />
    </div>
  );
}
