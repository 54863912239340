import axios from 'axios';
import React from 'react';
import ReactModal from 'react-modal';

import { getToken } from '../utils/token';
import copyObject from '../utils/copyObject';

import { getCurrentDate } from '../utils/dates';
import { formatValue, removeSpecialCharacters } from '../utils/formatValues';

import Toast from './Toast';
import FormButton from './FormButton';
import CircleButton from './CircleButton';
import TechniciansDatalist from './TechniciansDatalist';

export default class NewOSModal extends React.Component {
  constructor(props) {
    super(props);

    this.itemsList = [];

    this.state = {
      OS: {
        itens: '',
        entry_NFL: '0',
        technician: '',
        description: '',
        labor_value: '',
        outgoing_NF: '0',
        departure_date: '',
        components_value: '',
        serial: props.serial,
        outgoing_freight: true,
        incoming_freight: true,
        incoming_shipping_company: '',
        outgoing_shipping_company: '',
        arrival_date: getCurrentDate(),
      },
      displayToast: false,
    };

    this.errorToastData = {
      title: 'Erro',
      color: 'bg-red-700',
      position: 'top_right',
      text: 'Falha ao salvar!',
      icon: 'exclamation-circle',
      click: this.setState.bind(this),
    };

    this.successToastData = {
      title: 'Sucesso',
      color: 'bg-green-600',
      position: 'top_right',
      text: 'Registro salvo!',
      icon: 'check-circle',
      click: this.setState.bind(this),
    };
  }

  genItemsList() {
    if (this.state.OS !== null) {
      this.itemsList = this.state.OS.itens.split(';');
    }
  }

  calcTotalValue = () => {
    const labor_value = Number(removeSpecialCharacters(this.state.OS.labor_value));
    const components_value = Number(removeSpecialCharacters(this.state.OS.components_value));
    return String(labor_value + components_value);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    if (!['incoming_freight', 'outgoing_freight'].includes(name)) {
      value = value.toUpperCase();
    }

    if (['labor_value', 'components_value'].includes(name)) {
      value = String(Number(removeSpecialCharacters(value)));
    }

    const newData = copyObject(this.state.OS);
    newData[name] = value;

    this.setState({
      OS: newData,
    });
  };

  handleItemsListChange = (event) => {
    const target = event.target;
    const id = target.id;

    this.itemsList[id] = target.value.toUpperCase();
    this.updateItemsList();
  };

  addItemToList = () => {
    this.itemsList.push('');
    this.updateItemsList();
  };

  removeItemFromList = () => {
    this.itemsList.pop();
    this.updateItemsList();
  };

  updateItemsList = () => {
    const newData = copyObject(this.state.OS);
    newData.itens = this.itemsList.join(';');

    this.setState({
      OS: newData,
    });
  };

  validateForm = () => {
    const form = document.querySelector('#form');
    return form.checkValidity();
  };

  saveOS = async () => {
    const payload = copyObject(this.state.OS);

    if (payload['departure_date'] === '') {
      delete payload['departure_date'];
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/service-orders/`, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        this.successfulUpdate = true;
        this.setState({
          displayToast: true,
        });
        setTimeout(window.location.reload.bind(window.location), 1000);
      })
      .catch((err) => {
        this.successfulUpdate = false;
        this.setState({ displayToast: true });
      });
  };

  render() {
    const formGroupStyle = 'flex-col ml-3';
    const textInputStyle = 'w-full h-8 pl-2 border rounded-md';
    const formRowStyle = 'flex mt-3 mr-3 justify-evenly';

    this.genItemsList();

    return (
      <ReactModal
        key={this.props.OS}
        ariaHideApp={false}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.hideMethod}
        style={{
          overlay: { backgroundColor: '#0005', overflowY: 'auto' },
          content: {
            height: 'fit-content',
            maxHeight: '720px',
            width: '650px',
            margin: 'auto',
            border: '2px solid #000c',
            backgroundColor: '#f3f4f5',
          },
        }}
      >
        {this.state.displayToast && this.successfulUpdate && <Toast data={this.successToastData} />}
        {this.state.displayToast && !this.successfulUpdate && <Toast data={this.errorToastData} />}

        <div className='flex justify-between text-3xl font-bold ml-3 mr-3 mb-5 mt-2'>
          <h2>ORDEM DE SERVIÇO</h2>
          <button className='font-bold text-gray-600' onClick={this.props.hideMethod}>
            X
          </button>
        </div>
        <form
          id='form'
          autoComplete='off'
          className='flex-col'
          onSubmit={(event) => {
            event.preventDefault();
            return false;
          }}
        >
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/2`}>
              <label className='' htmlFor='arrival_date'>
                Data de entrada
              </label>
              <input
                className={`${textInputStyle}`}
                type='date'
                name='arrival_date'
                value={this.state.OS.arrival_date}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='entry_NF'>
                NF de entrada
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='entry_NF'
                value={this.state.OS.entry_NF}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='incoming_freight'>
                Frete de entrada
              </label>
              <select
                className={`${textInputStyle} bg-white`}
                name='incoming_freight'
                value={this.state.OS.incoming_freight}
                onChange={this.handleInputChange}
              >
                <option value='false'>Nós</option>
                <option value='true'>Cliente</option>
              </select>
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-full`}>
              <label className='' htmlFor='incoming_shipping_company'>
                Transportadora de entrada
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='incoming_shipping_company'
                value={this.state.OS.incoming_shipping_company}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-full`}>
              <label className='' htmlFor='description'>
                Condição do equipamento
              </label>
              <textarea
                className='w-full pl-2 border rounded-md '
                name='description'
                rows='3'
                value={this.state.OS.description}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-full`}>
              <label className=''>Serviço</label>
              {this.itemsList.map((item, index) => (
                <div className='flex-row w-full h-10 my-2 border rounded-md border-gray-300' key={index}>
                  <span className='inline-flex h-full w-2/12 font-light justify-center items-center rounded-l-md bg-gray-200'>
                    {`Item ${index + 1}`}
                  </span>
                  <input
                    type='text'
                    id={index}
                    key={index}
                    value={item}
                    onChange={this.handleItemsListChange}
                    className={`h-full w-10/12 pl-3 align-top rounded-r-md`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={`flex mt-2 mr-3 justify-evenly`}>
            <div className={`flex justify-center w-full`}>
              <CircleButton size='12' color='blue-500' icon='plus' custom_style='mr-5' click={this.addItemToList} />
              <CircleButton
                size='12'
                color='red-500'
                icon='minus'
                custom_style='ml-5'
                click={this.removeItemFromList}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/2`}>
              <label className='' htmlFor='departure_date'>
                Data de saída
              </label>
              <input
                className={`${textInputStyle}`}
                type='date'
                name='departure_date'
                value={this.state.OS.departure_date || ''}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='outgoing_NF'>
                NF de saída
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='outgoing_NF'
                value={this.state.OS.outgoing_NF}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='incoming_freight'>
                Frete de saída
              </label>
              <select
                className={`${textInputStyle} bg-white`}
                name='outgoing_freight'
                value={this.state.OS.outgoing_freight}
                onChange={this.handleInputChange}
              >
                <option value='false'>Nós</option>
                <option value='true'>Cliente</option>
              </select>
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-full`}>
              <label className='' htmlFor='outgoing_shipping_company'>
                Transportadora de saída
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='outgoing_shipping_company'
                value={this.state.OS.outgoing_shipping_company}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/2`}>
              <label className='' htmlFor='components_value'>
                Componentes
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='components_value'
                value={formatValue(this.state.OS.components_value)}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='labor_value'>
                Mão de obra
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='labor_value'
                value={formatValue(this.state.OS.labor_value)}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='total_value'>
                Valor total
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='total_value'
                value={formatValue(this.calcTotalValue())}
                onChange={this.handleInputChange}
                disabled
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-full`}>
              <label className='' htmlFor='technician'>
                Técnico responsável
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='technician'
                list='technicians-list'
                value={this.state.OS.technician}
                onChange={this.handleInputChange}
                autoComplete='off'
                required
              />
              <TechniciansDatalist field='technician' id='technicians-list' />
            </div>
          </div>
          <div className='flex justify-start ml-3 mt-5'>
            <FormButton
              click={() => {
                if (this.validateForm()) this.saveOS();
              }}
              type='submit'
              color='green-500'
              width='w-32'
              text='Cadastrar'
              icon='save'
            />
          </div>
        </form>
      </ReactModal>
    );
  }
}
