export default function Select(props) {
  return (
    <select className={props.className} onChange={props.onChange}>
      {Object.entries(props.optionValues).map((entry) => {
        const [option, value] = entry;
        return (
          <option key={value} value={value}>
            {option}
          </option>
        );
      })}
    </select>
  );
}
