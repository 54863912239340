import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FormButton(props) {
  return (
    <button
      className={`bg-${props.color} ${props.width} ${props.custom_style} h-10 mr-1 rounded-md duration-300 transform hover:scale-105 text-white`}
      onClick={props.click}
      type={props.type || 'button'}
    >
      <FontAwesomeIcon icon={props.icon} className='text-xl mr-2' />
      {props.text}
    </button>
  );
}
