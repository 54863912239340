import axios from 'axios';
import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from '../components/Select';
import SearchOSTable from '../components/SearchOSTable';

import { getToken } from '../utils/token';

export default class SearchOSPage extends React.Component {
  constructor(props) {
    super(props);

    this.OSFilter = 'NF';
    this.searchText = '';
    this.searchColumn = 'id';

    this.state = {
      loading: false,
      serviceOrders: [],
    };
  }

  componentDidMount() {
    this.getServiceOrders();
  }

  handleColumnSelectChanges = (e) => {
    this.searchColumn = e.target.value;
    this.startPreRequestTimeout();
  };

  handleSituationSelectChanges = (e) => {
    this.OSFilter = e.target.value;
    this.startPreRequestTimeout();
  };

  handleTextInput = (e) => {
    this.searchText = e.target.value.toUpperCase();
    this.startPreRequestTimeout();
  };

  startPreRequestTimeout = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.getServiceOrders.bind(this), 1000);
  };

  filterOSs = () => {
    switch (this.OSFilter) {
      case 'ALL':
        return this.state.serviceOrders;
      case 'F':
        return this.state.serviceOrders.filter((os) => {
          return moment(os.departure_date, 'YYYY/MM/DD').isValid();
        });
      case 'NF':
        return this.state.serviceOrders.filter((os) => {
          return !moment(os.departure_date, 'YYYY/MM/DD').isValid();
        });
      default:
        return [];
    }
  };

  async getServiceOrders() {
    this.setState({ loading: true });
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/service-orders/?${this.searchColumn}=${this.searchText}`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    this.setState({ serviceOrders: response.data, loading: false });
  }

  render() {
    const OSsToDisplay = this.filterOSs();
    return (
      <div className='inline-block h-screen overflow-x-hidden overflow-y-scroll w-full bg-secondary'>
        <nav className='w-full h-20 flex sticky top-0 items-center justify-center bg-secondary'>
          <div className='h-10 mr-10 border rounded border-gray-300'>
            <Select
              className='h-full w-32 pl-2 outline-none border-r border-gray-300 bg-white'
              optionValues={{
                OS: 'id',
                Técnico: 'technician',
              }}
              onChange={this.handleColumnSelectChanges}
            />
            <input
              type='text'
              className='h-9 w-52 px-4 uppercase outline-none focus:ring-2 focus:ring-tertiary'
              autoFocus
              onInput={this.handleTextInput}
            />
            <FontAwesomeIcon icon='search' className='mx-3 text-blue-600' />
          </div>
          <div className='h-10 mr-10 border rounded border-gray-300 bg-gray-200'>
            <span className='h-full mx-4'>Situação</span>
            <Select
              className='h-full w-52 pl-2 outline-none border-l border-gray-300 bg-white'
              optionValues={{
                'NÃO FINALIZADAS': 'NF',
                FINALIZADAS: 'F',
                TODAS: 'ALL',
              }}
              onChange={this.handleSituationSelectChanges}
            />
          </div>
        </nav>
        <SearchOSTable
          headerWords={['OS', 'Serial', 'Entrada', 'Saída', 'Técnico', 'Componentes', 'Mão de obra', 'Valor total']}
          serviceOrders={OSsToDisplay}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
