import React from 'react';

export default class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.first_request = true;
    this.state = { uniqueValues: [], options: [] };
  }

  componentDidMount() {
    this.getFieldUniqueValues();
  }

  componentDidUpdate() {
    if (this.first_request) {
      this.first_request = false
      this.generateDatalistOptions();
    }
  }

  getFieldUniqueValues = () => {
    let values = [];
    for (const equipment of this.props.equipments) {
      if (equipment[this.props.field]) {
        values.push(equipment[this.props.field]);
      }
    }
    this.setState({ uniqueValues: Array.from(new Set(values)) });
  };

  generateDatalistOptions = () => {
    this.setState({ options: this.state.uniqueValues.map(this.valueToElement) });
  };

  valueToElement = (value) => {
    return <option key={value} value={value}></option>;
  };

  render() {
    return <datalist id={this.props.id}>{this.state.options}</datalist>;
  }
}
