import axios from 'axios';
import React from 'react';

import { getToken } from '../utils/token';

export default class ModelSelect extends React.Component {
  constructor() {
    super();
    this.modelsByCategoryJSON = {};
    this.state = { modelsArray: [], optGroups: [] };
  }

  componentDidMount() {
    this.getModels();
  }

  componentDidUpdate() {
    if (this.state.modelsArray.length > 0 && this.state.optGroups.length === 0) {
      this.organizeModelsByCategory();
      this.generateSelectOptions();
    }
  }

  getModels = async () => {
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/models/`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    this.setState({ modelsArray: response.data });
  };

  organizeModelsByCategory = () => {
    for (const model of this.state.modelsArray) {
      if (!this.modelsByCategoryJSON.hasOwnProperty(model.category)) {
        this.modelsByCategoryJSON[model.category] = [];
      }
      this.modelsByCategoryJSON[model.category].push(model);
    }
  };

  generateSelectOptions = () => {
    const newOptGroups = [];
    for (const [category, models] of Object.entries(this.modelsByCategoryJSON)) {
      const options = [];
      for (const model of models) {
        model.id = String(model.id);
        if (model.id.length === 2) model.id = '0' + model.id;

        options.push(
          <option key={model.id} value={model.id}>
            {model.name}
          </option>
        );
      }
      newOptGroups.push(
        <optgroup key={category} label={category}>
          {options}
        </optgroup>
      );
    }
    this.setState({ optGroups: newOptGroups });
  };

  render() {
    return (
      <select
        className='w-full h-9 pl-2 border rounded-md bg-white'
        onChange={(event) => {
          this.props.change(event);
          document.querySelector('#NF').focus();
        }}
        defaultValue='default'
        name='modelo'
      >
        <option value='default' disabled hidden>
          Selecione o modelo
        </option>
        {this.state.optGroups}
      </select>
    );
  }
}
