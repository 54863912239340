import './App.css';

import ViewPage from './pages/ViewPage';
import LoginPage from './pages/LoginPage';
import StockPage from './pages/StockPage';
import Sidebar from './components/Sidebar';
import ModelsPage from './pages/ModelsPage';
import SearchPage from './pages/SearchPage';
import RegisterPage from './pages/RegisterPage';
import SearchOSPage from './pages/SearchOSPage';
import SearchComponentsPage from './pages/SearchComponentsPage';
import SearchCompoundComponentsPage from './pages/SearchCompoundComponentsPage';

import { getToken, setToken } from './utils/token';
import { useNavigate } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBan,
  faCog,
  faCogs,
  faList,
  faSave,
  faEdit,
  faPlus,
  faBoxes,
  faMinus,
  faTools,
  faTrash,
  faCheck,
  faSearch,
  faFileCsv,
  faChartBar,
  faTicketAlt,
  faCheckCircle,
  faClipboardList,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBan,
  faCog,
  faCogs,
  faList,
  faSave,
  faEdit,
  faPlus,
  faBoxes,
  faMinus,
  faTools,
  faTrash,
  faCheck,
  faSearch,
  faFileCsv,
  faChartBar,
  faTicketAlt,
  faCheckCircle,
  faClipboardList,
  faExclamationCircle
  );
  
  export default function App() {
    const navigate = useNavigate();
    
    const token = getToken();
    if (!token) {
      return <LoginPage setToken={setToken} navigate={navigate} />;
    }
    
    return (
    <div className='min-h-screen overflow-x-hidden flex bg-primary text-md'>
      <Sidebar />
      <Routes>
        <Route path='*' element={<SearchPage />} />
        <Route path='view/*' element={<ViewPage />} />
        <Route path='models/' element={<ModelsPage />} />
        <Route path='search/' element={<SearchPage />} />
        <Route path='inventory/' element={<StockPage />} />
        <Route path='register/' element={<RegisterPage />} />
        <Route path='service-orders/' element={<SearchOSPage />} />
        <Route path='components/' element={<SearchComponentsPage />} />
        <Route path='compound-components/' element={<SearchCompoundComponentsPage />} />
      </Routes>
    </div>
  );
}
