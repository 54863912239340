import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';

class Etiqueta {
  constructor(x1, x2, name, serial, date, N = 4) {
    this.setCoords(x1, x2);
    this.setSerial(serial);
    this.setName(name);
    this.setDate(date);
    this.setN(N);
  }

  setCoords(x1, x2) {
    this.nameX1 = Number(x1);
    this.nameX2 = Number(x2);
    this.backupX1 = x1;
    this.backupX2 = x2;
  }

  setName(name) {
    let aux;
    this.name = name;

    aux = this.name.split('-');

    this.name1 = aux[0];
    this.name2 = aux[1];
  }

  setSerial(serial) {
    this.serial = serial;
  }

  setDate(date) {
    let aux = date.split('-');
    this.date = aux[2] + '/' + aux[1] + '/' + aux[0];
  }

  setN(N) {
    this.N = N;
  }

  nameX1_R() {
    this.nameX1 += 0.5;
  }

  nameX1_L() {
    this.nameX1 -= 0.5;
  }

  nameX2_R() {
    this.nameX2 += 0.5;
  }

  nameX2_L() {
    this.nameX2 -= 0.5;
  }

  makeBarcode(code) {
    //Remove canvas anterior
    let element = document.querySelector('#barcode');
    if (element !== null) element.remove();

    //Gera canvas que receberá código de barras
    element = document.createElement(`template`);
    element.innerHTML = `<canvas id="barcode" hidden></canvas>`;
    element = element.content.firstChild;
    document.body.appendChild(element);

    //Gera código de barras
    JsBarcode('#barcode', code, {
      format: 'CODE128',
      lineColor: 'black',
      width: 1,
      height: 42,
      displayValue: false,
    });

    //Seleciona o canvas
    this.canvas = document.getElementById('barcode');
  }

  makePDF() {
    //Cria novo PDF
    this.doc = new jsPDF('l', 'mm', [58, 20]);

    //Configura fonte do PDF
    this.doc.setFontSize(13);

    //Loop pelos seriais
    for (let code of this.serial) {
      //Gera código de barras
      this.makeBarcode(code);

      //Gera repetições das etiquetas
      for (let i = 0; i < this.N; i++) {
        //Adiciona o código de barras ao PDF
        this.doc.addImage(this.canvas.toDataURL('image/jpeg'), 'JPEG', 34, 0.3);

        //Configura a fonte como normal
        this.doc.setFont('helvetica', 'normal');

        //Muda cor do texto para preto
        this.doc.setTextColor(0, 0, 0);

        //Insere serial na etiqueta
        this.serialWSpace = code.substr(0, 4) + ' ' + code.substr(4);
        this.doc.text(36, 18.5, this.serialWSpace);

        //Insere retângulo preto
        this.doc.rect(9, 3.1, 25, 11, 'F');

        //Configura a fonte como negrito
        this.doc.setFont('helvetica', 'bold');

        //Insere data
        this.doc.text(9.7, 18.5, this.date);

        //Muda cor do texto para branco
        this.doc.setTextColor(255, 255, 255);

        //Insere primeira parte do modelo
        this.doc.text(this.nameX1, 7.5, this.name1);

        //Segunda parte do modelo
        this.doc.text(this.nameX2, 13, this.name2);

        //Adiciona página ao PDF
        if (
          this.doc.internal.getNumberOfPages() !==
          this.serial.length * this.N
        )
          this.doc.addPage();
      }
    }
  }
  /*
    renderPDF(){
        //PDF -> Base64 String
        this.string64 = this.doc.output('datauristring')
        this.string64 = this.string64.substr(28)

        //Base64 -> Bin
        let pdfData = atob(this.string64)
        let loadingTask = pdfjsLib.getDocument({data: pdfData});
        loadingTask.promise.then(function(pdf){
            console.log('PDF loaded');
            
            // Fetch the first page
            let pageNumber = 1;
            pdf.getPage(pageNumber).then(function(page){
                console.log('Page loaded');
                
                let scale = 1.5;
                let viewport = page.getViewport({scale: scale});
    
                // Prepare canvas using PDF page dimensions
                let canvas = document.getElementById('the-canvas');
                let context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
    
                // Render PDF page into canvas context
                let renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
    
                let renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                    console.log('Page rendered');
                });
            });
        }, function (reason) {
            // PDF loading error
            console.error(reason);
        });
    }
    */
  downloadPDF() {
    this.doc.save('etiqueta' + this.name + '.pdf');
  }
}

export { Etiqueta };
