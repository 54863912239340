import axios from 'axios';
import React from 'react';
import ReactModal from 'react-modal';

import { getToken } from '../utils/token';
import copyObject from '../utils/copyObject';

import { formatValue, removeSpecialCharacters } from '../utils/formatValues';

import Toast from './Toast';
import FormButton from './FormButton';
import CircleButton from './CircleButton';
import TechniciansDatalist from './TechniciansDatalist';

export default class OSModal extends React.Component {
  constructor(props) {
    super(props);

    this.itemsList = [];

    this.state = {
      editingMode: false,
      displayToast: false,
      OSToShow: copyObject(props.OSToShow),
      OSToShowCopy: copyObject(props.OSToShow),
    };

    this.errorToastData = {
      title: 'Erro',
      color: 'bg-red-700',
      position: 'top_right',
      text: 'Falha ao atualizar!',
      icon: 'exclamation-circle',
      click: this.setState.bind(this),
    };

    this.successToastData = {
      title: 'Sucesso',
      color: 'bg-green-600',
      position: 'top_right',
      text: 'Registro atualizado!',
      icon: 'check-circle',
      click: this.setState.bind(this),
    };
  }

  componentDidUpdate() {
    if (this.state.OSToShow !== null) {
      if (this.props.OSToShow.id !== this.state.OSToShow.id) {
        this.setState({ OSToShow: this.props.OSToShow });
      }
    }
  }

  genItemsList() {
    if (this.state.OSToShow !== null) {
      this.itemsList = this.state.OSToShow.itens.split(';');
    }
  }

  calcTotalValue = () => {
    const labor_value = Number(removeSpecialCharacters(this.state.OSToShow.labor_value));
    const components_value = Number(removeSpecialCharacters(this.state.OSToShow.components_value));
    return String(labor_value + components_value);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    if (!['incoming_freight', 'outgoing_freight'].includes(name)) {
      value = value.toUpperCase();
    }

    if (['labor_value', 'components_value'].includes(name)) {
      value = String(Number(removeSpecialCharacters(value)));
    }

    const newData = copyObject(this.state.OSToShow);
    newData[name] = value;

    this.setState({
      OSToShow: newData,
    });
  };

  handleItemsListChange = (event) => {
    const target = event.target;
    const id = target.id;

    this.itemsList[id] = target.value.toUpperCase();
    this.updateItemsList();
  };

  addItemToList = () => {
    this.itemsList.push('');
    this.updateItemsList();
  };

  removeItemFromList = () => {
    this.itemsList.pop();
    this.updateItemsList();
  };

  updateItemsList = () => {
    const newData = copyObject(this.state.OSToShow);
    newData.itens = this.itemsList.join(';');

    this.setState({
      OSToShow: newData,
    });
  };

  startEditing = () => {
    this.setState({ editingMode: true });
  };

  cancelEdit = () => {
    this.setState({ editingMode: false });
    this.restoreOriginalOSData();
  };

  restoreOriginalOSData = () => {
    this.setState({ OSToShow: copyObject(this.state.OSToShowCopy) });
  };

  saveEditing = async () => {
    this.setState({ editingMode: false });

    const payload = copyObject(this.state.OSToShow);
    if (payload['arrival_date'] === '0000-00-00') {
      payload['arrival_date'] = null;
    }
    if (payload['departure_date'] === '0000-00-00') {
      payload['departure_date'] = null;
    }

    axios
      .patch(`${process.env.REACT_APP_API_URL}/service-orders/${this.state.OSToShow.id}/`, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        this.successfulUpdate = true;
        this.setState({
          displayToast: true,
          OSToShow: copyObject(response.data),
          OSToShowCopy: copyObject(response.data),
        });
      })
      .catch((err) => {
        this.successfulUpdate = false;
        this.restoreOriginalOSData();
        this.setState({ displayToast: true });
      });
  };

  deleteOS = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/service-orders/${this.state.OSToShow.id}/`, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const formGroupStyle = 'flex-col ml-3';
    const textInputStyle = 'w-full h-8 pl-2 border rounded-md';
    const formRowStyle = 'flex mt-3 mr-3 justify-evenly';

    this.genItemsList();

    if (this.state.OSToShow) {
      return (
        <ReactModal
          key={this.props.OSToShow}
          ariaHideApp={false}
          isOpen={this.props.isOpen}
          onRequestClose={this.props.hideMethod}
          style={{
            overlay: { backgroundColor: '#0005', overflowY: 'auto' },
            content: {
              height: 'fit-content',
              maxHeight: '720px',
              width: '650px',
              margin: 'auto',
              border: '2px solid #000c',
              backgroundColor: '#f3f4f5',
            },
          }}
        >
          {this.state.displayToast && this.successfulUpdate && <Toast data={this.successToastData} />}
          {this.state.displayToast && !this.successfulUpdate && <Toast data={this.errorToastData} />}

          <div className='flex justify-between text-3xl font-bold ml-3 mr-3 mb-5 mt-2'>
            <h2>OS-{this.state.OSToShow.id}</h2>
            <button className='font-bold text-gray-600' onClick={this.props.hideMethod}>
              X
            </button>
          </div>
          <form className='flex-col'>
            <div className={`${formRowStyle}`}>
              <div className={`${formGroupStyle} w-1/2`}>
                <label className='' htmlFor='arrival_date'>
                  Data de entrada
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='date'
                  name='arrival_date'
                  value={this.state.OSToShow.arrival_date}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                />
              </div>
              <div className={`${formGroupStyle} w-1/4`}>
                <label className='' htmlFor='entry_NF'>
                  NF de entrada
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='text'
                  name='entry_NF'
                  value={this.state.OSToShow.entry_NF}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                />
              </div>
              <div className={`${formGroupStyle} w-1/4`}>
                <label className='' htmlFor='incoming_freight'>
                  Frete de entrada
                </label>
                <select
                  className={`${textInputStyle} ${!this.state.editingMode ? 'bg-gray-100' : 'bg-white'}`}
                  name='incoming_freight'
                  value={this.state.OSToShow.incoming_freight}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                >
                  <option value='false'>Nós</option>
                  <option value='true'>Cliente</option>
                </select>
              </div>
            </div>
            <div className={`${formRowStyle}`}>
              <div className={`${formGroupStyle} w-full`}>
                <label className='' htmlFor='incoming_shipping_company'>
                  Transportadora de entrada
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='text'
                  name='incoming_shipping_company'
                  value={this.state.OSToShow.incoming_shipping_company}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                />
              </div>
            </div>
            <div className={`${formRowStyle}`}>
              <div className={`${formGroupStyle} w-full`}>
                <label className='' htmlFor='description'>
                  Condição do equipamento
                </label>
                <textarea
                  className='w-full pl-2 border rounded-md '
                  name='description'
                  rows='3'
                  value={this.state.OSToShow.description}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                />
              </div>
            </div>
            <div className={`${formRowStyle}`}>
              <div className={`${formGroupStyle} w-full`}>
                <label className=''>Serviço</label>
                {this.itemsList.map((item, index) => (
                  <div className='flex-row w-full h-10 my-2 border rounded-md border-gray-300' key={index}>
                    <span className='inline-flex h-full w-2/12 font-light justify-center items-center rounded-l-md bg-gray-200'>
                      {`Item ${index + 1}`}
                    </span>
                    <input
                      type='text'
                      id={index}
                      key={index}
                      value={item}
                      onChange={this.handleItemsListChange}
                      className={`h-full w-10/12 pl-3 align-top rounded-r-md`}
                      disabled={!this.state.editingMode}
                    />
                  </div>
                ))}
              </div>
            </div>
            {this.state.editingMode && (
              <div className={`flex mt-2 mr-3 justify-evenly`}>
                <div className={`flex justify-center w-full`}>
                  <CircleButton size='12' color='blue-500' icon='plus' custom_style='mr-5' click={this.addItemToList} />
                  <CircleButton
                    size='12'
                    color='red-500'
                    icon='minus'
                    custom_style='ml-5'
                    click={this.removeItemFromList}
                  />
                </div>
              </div>
            )}
            <div className={`${formRowStyle}`}>
              <div className={`${formGroupStyle} w-1/2`}>
                <label className='' htmlFor='departure_date'>
                  Data de saída
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='date'
                  name='departure_date'
                  value={this.state.OSToShow.departure_date || ''}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                />
              </div>
              <div className={`${formGroupStyle} w-1/4`}>
                <label className='' htmlFor='outgoing_NF'>
                  NF de saída
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='text'
                  name='outgoing_NF'
                  value={this.state.OSToShow.outgoing_NF}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                />
              </div>
              <div className={`${formGroupStyle} w-1/4`}>
                <label className='' htmlFor='incoming_freight'>
                  Frete de saída
                </label>
                <select
                  className={`${textInputStyle}  ${!this.state.editingMode ? 'bg-gray-100' : 'bg-white'}`}
                  name='outgoing_freight'
                  value={this.state.OSToShow.outgoing_freight}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                >
                  <option value='false'>Nós</option>
                  <option value='true'>Cliente</option>
                </select>
              </div>
            </div>
            <div className={`${formRowStyle}`}>
              <div className={`${formGroupStyle} w-full`}>
                <label className='' htmlFor='outgoing_shipping_company'>
                  Transportadora de saída
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='text'
                  name='outgoing_shipping_company'
                  value={this.state.OSToShow.outgoing_shipping_company}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                />
              </div>
            </div>
            <div className={`${formRowStyle}`}>
              <div className={`${formGroupStyle} w-1/2`}>
                <label className='' htmlFor='components_value'>
                  Componentes
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='text'
                  name='components_value'
                  value={formatValue(this.state.OSToShow.components_value)}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                />
              </div>
              <div className={`${formGroupStyle} w-1/4`}>
                <label className='' htmlFor='labor_value'>
                  Mão de obra
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='text'
                  name='labor_value'
                  value={formatValue(this.state.OSToShow.labor_value)}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                />
              </div>
              <div className={`${formGroupStyle} w-1/4`}>
                <label className='' htmlFor='total_value'>
                  Valor total
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='text'
                  name='total_value'
                  value={formatValue(this.calcTotalValue())}
                  onChange={this.handleInputChange}
                  disabled
                />
              </div>
            </div>
            <div className={`${formRowStyle}`}>
              <div className={`${formGroupStyle} w-full`}>
                <label className='' htmlFor='technician'>
                  Técnico responsável
                </label>
                <input
                  className={`${textInputStyle}`}
                  type='text'
                  name='technician'
                  list='technicians-list'
                  value={this.state.OSToShow.technician}
                  onChange={this.handleInputChange}
                  disabled={!this.state.editingMode}
                  autoComplete='off'
                />
                <TechniciansDatalist field='technician' id='technicians-list' />
              </div>
            </div>
          </form>
          {this.state.editingMode && (
            <div className='flex mt-5'>
              <div className='justify-start ml-3'>
                <FormButton click={this.cancelEdit} color='red-500' width='w-32' text='Cancelar' icon='ban' />
              </div>
              <div className='justify-end'>
                <FormButton click={this.saveEditing} color='green-500' width='w-28' text='Salvar' icon='save' />
              </div>
            </div>
          )}
          {!this.state.editingMode && (
            <div className='flex justify-between ml-3 mt-5'>
              <FormButton click={this.startEditing} color='yellow-500' width='w-28' text='Editar' icon='edit' />
              <FormButton
                click={this.deleteOS}
                color='red-500'
                width='w-28'
                text='Deletar'
                icon='trash'
                custom_style='mr-4'
              />
            </div>
          )}
        </ReactModal>
      );
    }
    return null;
  }
}
