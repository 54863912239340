import axios from 'axios';
import React from 'react';
import ReactModal from 'react-modal';

import { getToken } from '../utils/token';
import copyObject from '../utils/copyObject';
import { formatValue, removeNonNumericCharacters } from '../utils/formatValues';

import Toast from './Toast';
import FormButton from './FormButton';

export default class EditModelModal extends React.Component {
  constructor(props) {
    super(props);

    const components = props.components;
    const componentsList = props.componentsList ? props.componentsList.components : [];

    componentsList.forEach((x) => {
      components.forEach((y) => {
        if (x.component === y.id) {
          y.N = x.amount;
        }
      });
    });

    this.state = {
      model: props.model,
      payload: {
        ncm: props.model.ncm,
        value: props.model.value,
      },
      displayToast: false,
      components: components,
      defaultSimpleComponents: components,
    };

    this.errorToastData = {
      title: 'Erro',
      color: 'bg-red-700',
      position: 'top_right',
      text: 'Falha ao salvar!',
      icon: 'exclamation-circle',
      click: this.setState.bind(this),
    };

    this.successToastData = {
      title: 'Sucesso',
      icon: 'check-circle',
      color: 'bg-green-600',
      position: 'top_right',
      text: 'Registro salvo!',
      click: this.setState.bind(this),
    };
  }

  componentDidUpdate() {
    const closedWithoutSave = this.state.defaultSimpleComponents !== this.state.components;
    if (!this.props.isOpen && closedWithoutSave) {
      this.setState({ components: this.state.defaultSimpleComponents });
    }
  }

  handleComponentsChange = (event) => {
    const target = event.target;
    const index = target.name;
    let value = target.value;

    if (value < 0) {
      return;
    }

    const newData = copyObject(this.state.components);
    newData[index].N = value;

    this.setState({
      components: newData,
    });
  };

  validateForm = () => {
    const form = document.querySelector('#form');
    return form.checkValidity();
  };

  updateModel = async () => {
    let payload = {
      subcomponents: this.state.components.filter((cmp) => {
        return Number(cmp.N) > 0;
      }),
      ...this.state.payload
    };
    axios
      .patch(`${process.env.REACT_APP_API_URL}/models/${this.state.model.id}/`, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        this.successfulUpdate = true;
        this.setState({
          displayToast: true,
        });
        setTimeout(window.location.reload.bind(window.location), 1000);
      })
      .catch((err) => {
        this.successfulUpdate = false;
        this.setState({ displayToast: true });
      });
  };

  render() {
    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.hideMethod}
        style={{
          overlay: { backgroundColor: '#0005', overflowY: 'auto' },
          content: {
            maxHeight: '720px',
            height: 'fit-content',
            width: '500px',
            margin: 'auto',
            border: '2px solid #000c',
            backgroundColor: '#f3f4f5',
          },
        }}
      >
        {this.state.displayToast && this.successfulUpdate && <Toast data={this.successToastData} />}
        {this.state.displayToast && !this.successfulUpdate && <Toast data={this.errorToastData} />}
        <div className='flex justify-between text-2xl font-bold'>
          <h2>Sub-componentes</h2>
          <button className='font-bold text-gray-600' onClick={this.props.hideMethod}>
            X
          </button>
        </div>
        <div className='flex text-2xl font-bolder'>
          <form
            id='form'
            autoComplete='off'
            className='flex-col text-base'
            onSubmit={(event) => {
              event.preventDefault();
              return false;
            }}
          >
            <div className='mt-4'>
              <label htmlFor='description'>Descrição</label>
              <input
                type='text'
                disabled={true}
                name='description'
                value={this.state.model.name}
                className='w-full h-9 pl-2 border border-gray-400 rounded-md'
              />
            </div>
            <div className='mt-4'>
              <label htmlFor='ncm'>NCM</label>
              <input
                type='text'
                name='ncm'
                maxLength={8}
                value={this.state.payload.ncm || ''}
                className='w-full h-9 pl-2 border border-gray-400 rounded-md'
                onChange={(event) => {
                  const value = event.target.value;
                  this.setState({
                    payload: {
                      ...this.state.payload,
                      ncm: value,
                    },
                  });
                }}
              />
            </div>
            <div className='mt-4'>
              <label htmlFor='value'>Valor</label>
              <input
                type='text'
                name='value'
                value={'R$ ' + (this.state.payload.value > 0? formatValue(this.state.payload.value) : '')}
                className='w-full h-9 pl-2 border border-gray-400 rounded-md'
                onChange={(event) => {
                  const value = String(Number(removeNonNumericCharacters(event.target.value)));
                  this.setState({
                    payload: {
                      ...this.state.payload,
                      value: value,
                    },
                  });
                }}
              />
            </div>
            <div className='mt-4'>
              <div className='flex justify-between'>
                <p>Sub-componente</p>
                <p>Quantidade</p>
              </div>
              <fieldset className='w-full border rounded-md overflow-hidden border-gray-400 bg-blue-400'>
                {this.state.components.map((cmp, index) => (
                  <div
                    className={`py-1 flex items-center justify-between overflow-none ${
                      cmp.N > 0 ? 'bg-blue-400' : 'bg-white'
                    }`}
                    key={index}
                  >
                    <label htmlFor={index} className='flex-1 pl-3'>
                      {cmp.description.substring(0, 40)}
                    </label>
                    <input
                      name={index}
                      type='number'
                      value={cmp.N}
                      className='w-1/6 bg-transparent outline-none text-center'
                      onChange={this.handleComponentsChange}
                    ></input>
                  </div>
                ))}
              </fieldset>
            </div>
          </form>
        </div>
        <div className='flex justify-between mt-5 mb-1'>
          <FormButton click={this.props.hideMethod} color='red-500' width='w-32' text='Cancelar' icon='ban' />
          <FormButton
            click={() => {
              if (this.validateForm()) this.updateModel();
            }}
            type='submit'
            color='green-500'
            width='w-32'
            text='Atualizar'
            icon='save'
          />
        </div>
      </ReactModal>
    );
  }
}
