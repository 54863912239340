function formatDate(date) {
  let formatted_date = new Date(date);
  formatted_date = formatted_date.toLocaleDateString('en-GB', {
    timeZone: 'UTC',
  });
  return formatted_date;
}

function formatSerial(serial) {
  serial = serial.replace(' ', '');
  if (serial.length > 4) serial = `${serial.substr(0, 4)} ${serial.substr(4, 8)}`;
  if (serial.length > 9) serial = serial.substr(0, 9);
  return serial;
}

function formatFractionalValue(value) {
  switch (value.length) {
    case 1:
      return `0,000${value}`;
    case 2:
      return `0,00${value}`;
    case 3:
      return `0,0${value}`;
    case 4:
      return `0,${value}`;
    case 5:
      return `${value.substring(0, 1)},${value.substring(1)}`;
    case 6:
      return `${value.substring(0, 2)},${value.substring(2)}`;
    case 7:
      return `${value.substring(0, 3)},${value.substring(3)}`;
    case 8:
      return `${value.substring(0, 1)}.${value.substring(1, 4)},${value.substring(4)}`;
    case 9:
      return `${value.substring(0, 2)}.${value.substring(2, 5)},${value.substring(5)}`;
    case 10:
      return `${value.substring(0, 3)}.${value.substring(3, 6)},${value.substring(6)}`;
    default:
      return '';
  }
}

function formatValue(value) {
  switch (value.length) {
    case 1:
      return `0,0${value}`;
    case 2:
      return `0,${value}`;
    case 3:
      return `${value.substring(0, 1)},${value.substring(1)}`;
    case 4:
      return `${value.substring(0, 2)},${value.substring(2)}`;
    case 5:
      return `${value.substring(0, 3)},${value.substring(3)}`;
    case 6:
      return `${value.substring(0, 1)}.${value.substring(1, 4)},${value.substring(4)}`;
    case 7:
      return `${value.substring(0, 2)}.${value.substring(2, 5)},${value.substring(5)}`;
    case 8:
      return `${value.substring(0, 3)}.${value.substring(3, 6)},${value.substring(6)}`;
    default:
      return '';
  }
}

function formatCPF(CPF) {
  CPF = removeNonNumericCharacters(CPF);
  CPF = limitStringLength(CPF, 11);
  return CPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

function formatPhoneNumber(phone) {
  phone = removeNonNumericCharacters(phone);
  if (phone.length >= 10) {
    return `(${phone.substring(0, 2)}) ${phone.substring(2, 7)}-${phone.substring(7, 11)}`;
  }
  return phone;
}

function removeNonNumericCharacters(string) {
  return string.replace(/\D/g, '');
}

function removeSpecialCharacters(string) {
  return string.replace(/[.,;:()-\s]/g, '');
}

function limitStringLength(string, maxLength) {
  return string.length > maxLength ? string.substring(0, maxLength) : string;
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export {
  isNumeric,
  formatCPF,
  formatDate,
  formatValue,
  formatSerial,
  formatPhoneNumber,
  formatFractionalValue,
  removeSpecialCharacters,
  removeNonNumericCharacters,
};
