import React from 'react';
import axios from 'axios';

import { getToken } from '../utils/token';
import { getCurrentDate } from '../utils/dates';
import copyObject from '../utils/copyObject';

import {
  formatCPF,
  formatValue,
  formatSerial,
  formatPhoneNumber,
  removeSpecialCharacters,
} from '../utils/formatValues';

import Toast from './Toast';
import TagsModal from './TagsModal';
import FormButton from './FormButton';
import ModelSelect from './ModelSelect';
import AutoComplete from './AutoComplete';
import TechniciansDatalist from './TechniciansDatalist';

export default class RegisterEquipmentSheet extends React.Component {
  constructor(props) {
    super(props);

    this.defaultEquipmentData = {
      N_equipments: 1,
      use_components: true,
      in_stock: true,
      modelo: '',
      serial: '',
      NF: 0,
      dealer: '',
      buyer: '',
      value: '0',
      client: '',
      CPF: '',
      client_phone: '',
      manufacturing_date: getCurrentDate(),
      start_of_warranty: getCurrentDate(),
      technician: '',
      comments: '',
    };

    this.state = {
      equipments: [],
      responseData: [],
      displayToast: false,
      displayTagsModal: false,
      equipmentData: copyObject(this.defaultEquipmentData),
    };

    this.N_tags = 4;

    this.errorToastData = {
      title: 'Erro',
      color: 'bg-red-700',
      position: 'top_right',
      text: 'Falha ao atualizar!',
      icon: 'exclamation-circle',
      click: this.setState.bind(this),
    };
  }

  componentDidMount() {
    this.getEquipments();
  }

  displayTagsModal = () => {
    this.setState({ displayTagsModal: true });
  };

  hideTagsModal = () => {
    this.setState({ displayTagsModal: false });
  };

  validateForm = () => {
    const form = document.querySelector('#form');
    return form.checkValidity();
  };

  getEquipments = async () => {
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/equipments/`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    this.setState({ equipments: response.data });
  };

  registerEquipments = async () => {
    const payload = copyObject(this.state.equipmentData);
    if (payload.value === '') {
      delete payload.value;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/equipments/`, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        if (this.state.equipmentData.N_equipments === 1) {
          window.location.assign(`./../view/#${this.state.equipmentData.serial}`);
        } else {
          this.setState({
            equipmentData: this.defaultEquipmentData,
            responseData: response.data,
            displayTagsModal: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          alert(`Erro!\nComponente insuficiente: ${err.response.data}`);
        }
      });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value.toUpperCase();

    if (['value', 'client_phone'].includes(name)) {
      value = removeSpecialCharacters(value);
    }

    if (name === 'modelo') {
      this.getSerial(value);
    }

    if (name === 'serial') {
      value = value.replaceAll(' ', '');
    }

    if (name === 'value') {
      value = String(Number(value));
    }

    const newData = copyObject(this.state.equipmentData);
    newData[name] = value;

    this.setState({
      equipmentData: newData,
    });
  };

  handleCheckboxChanges = (event) => {
    const target = event.target;
    const name = target.name;

    const newData = copyObject(this.state.equipmentData);
    newData[name] = target.checked;

    this.setState({
      equipmentData: newData,
    });
  };

  getSerial = async (modelCode) => {
    if (modelCode.length === 2) modelCode = '0' + modelCode;
    axios
      .get(`${process.env.REACT_APP_API_URL}/equipments/generate_serial/?model=${modelCode}`, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        const newData = copyObject(this.state.equipmentData);
        newData['serial'] = response.data;
        this.setState({
          equipmentData: newData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const formGroupStyle = 'flex-col ml-3';
    const formRowStyle = 'flex mt-4 mr-3 justify-evenly';
    const textInputStyle = 'w-full h-9 pl-2 border rounded-md';

    return (
      <div className='w-4/5 max-w-6xl border-2 rounded border-primary' id='view'>
        {this.state.displayToast && !this.successfulUpdate && <Toast data={this.errorToastData} />}
        <form
          id='form'
          className='flex-col'
          autoComplete='off'
          onSubmit={(event) => {
            event.preventDefault();
            return false;
          }}
        >
          <h1 className='text-center text-3xl my-6'>CADASTRAR EQUIPAMENTO</h1>
          <TagsModal
            isOpen={this.state.displayTagsModal}
            hideMethod={this.hideTagsModal}
            afterDownloadMethod={() => {
              window.location.assign(`./../view/#${this.state.responseData[0].serial}`);
            }}
            equipmentsData={this.state.responseData}
          />
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/2`}>
              <label className='' htmlFor='modelo'>
                Modelo
              </label>
              <ModelSelect change={this.handleInputChange.bind(this)} />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='serial'>
                Serial
              </label>
              <input
                required
                type='text'
                name='serial'
                className={`${textInputStyle}`}
                onChange={this.handleInputChange}
                value={formatSerial(this.state.equipmentData.serial)}
              />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='NF'>
                NF
              </label>
              <input
                required
                id='NF'
                name='NF'
                type='number'
                onChange={this.handleInputChange}
                value={this.state.equipmentData.NF}
                className={`${textInputStyle} editable`}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/2`}>
              <label className='' htmlFor='dealer'>
                Revendedor
              </label>
              <input
                type='text'
                name='dealer'
                list='dealers-list'
                onChange={this.handleInputChange}
                value={this.state.equipmentData.dealer}
                className={`${textInputStyle} editable`}
              />
              {this.state.equipments.length > 0 && (
                <AutoComplete
                  field='dealer'
                  id='dealers-list'
                  equipments={this.state.equipments}
                  value={this.state.equipmentData.dealer}
                />
              )}
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='buyer'>
                Comprador
              </label>
              <input
                type='text'
                name='buyer'
                list='buyers-list'
                onChange={this.handleInputChange}
                value={this.state.equipmentData.buyer}
                className={`${textInputStyle} editable`}
              />
              {this.state.equipments.length > 0 && (
                <AutoComplete
                  field='buyer'
                  id='buyers-list'
                  equipments={this.state.equipments}
                  value={this.state.equipmentData.dealer}
                />
              )}
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='value'>
                Valor
              </label>
              <input
                type='text'
                name='value'
                required={true}
                onChange={this.handleInputChange}
                className={`${textInputStyle} editable`}
                value={formatValue(this.state.equipmentData.value)}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='client'>
                Cliente final
              </label>
              <input
                type='text'
                name='client'
                list='clients-list'
                onChange={this.handleInputChange}
                value={this.state.equipmentData.client}
                className={`${textInputStyle} editable`}
              />
              {this.state.equipments.length > 0 && (
                <AutoComplete
                  field='client'
                  id='clients-list'
                  equipments={this.state.equipments}
                  value={this.state.equipmentData.dealer}
                />
              )}
            </div>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='CPF'>
                CPF
              </label>
              <input
                name='CPF'
                type='text'
                list='CPFs-list'
                onChange={this.handleInputChange}
                className={`${textInputStyle} editable`}
                value={formatCPF(this.state.equipmentData.CPF)}
              />
              {this.state.equipments.length > 0 && (
                <AutoComplete
                  field='CPF'
                  id='CPFs-list'
                  equipments={this.state.equipments}
                  value={this.state.equipmentData.dealer}
                />
              )}
            </div>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='client_phone'>
                Telefone
              </label>
              <input
                type='text'
                list='phones-list'
                name='client_phone'
                onChange={this.handleInputChange}
                className={`${textInputStyle} editable`}
                value={formatPhoneNumber(this.state.equipmentData.client_phone)}
              />
              {this.state.equipments.length > 0 && (
                <AutoComplete
                  field='client_phone'
                  id='phones-list'
                  equipments={this.state.equipments}
                  value={this.state.equipmentData.dealer}
                />
              )}
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='manufacturing_date'>
                Data de fabricação
              </label>
              <input
                type='date'
                name='manufacturing_date'
                onChange={this.handleInputChange}
                className={`${textInputStyle} editable`}
                value={this.state.equipmentData.manufacturing_date}
              />
            </div>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='start_of_warranty'>
                Início da garantia
              </label>
              <input
                type='date'
                name='start_of_warranty'
                onChange={this.handleInputChange}
                className={`${textInputStyle} editable`}
                value={this.state.equipmentData.start_of_warranty}
              />
            </div>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='technician'>
                Técnico responsável
              </label>
              <input
                required
                type='text'
                name='technician'
                list='technicians-list'
                onChange={this.handleInputChange}
                className={`${textInputStyle} editable`}
                value={this.state.equipmentData.technician}
              />
              {this.state.equipments.length > 0 && <TechniciansDatalist field='technician' id='technicians-list' />}
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-full`}>
              <label className='' htmlFor='comments'>
                Comentários
              </label>
              <textarea
                rows='5'
                name='comments'
                onChange={this.handleInputChange}
                value={this.state.equipmentData.comments}
                className='w-full pl-2 border rounded-md editable'
              />
            </div>
          </div>
          <div className='flex ml-3 mr-3 mt-2 mb-4 justify-between'>
            <FormButton
              click={() => {
                if (this.validateForm()) this.registerEquipments();
              }}
              type='submit'
              color='green-500'
              width='w-32'
              text='Cadastrar'
              icon='save'
            />
            <div className='h-10 flex items-center'>
              <input
                type='checkbox'
                name='use_components'
                className='w-6 h-6 mr-2'
                onChange={this.handleCheckboxChanges}
                checked={this.state.equipmentData.use_components}
              />
              <label htmlFor='use_components'>Consumir componentes</label>
            </div>
            <div className='h-10 flex items-center'>
              <input
                type='checkbox'
                name='in_stock'
                className='w-6 h-6 mr-2'
                onChange={this.handleCheckboxChanges}
                checked={this.state.equipmentData.in_stock}
              />
              <label htmlFor='in_stock'>Em estoque</label>
            </div>
            <div className='flex border rounded-md'>
              <span className='flex justify-center items-center w-32 rounded-l-md bg-gray-300'>Quantidade:</span>
              <input
                min='1'
                name='N_equipments'
                type='number'
                onChange={this.handleInputChange}
                value={this.state.equipmentData.N_equipments}
                className='w-36 rounded-r-md text-center'
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
