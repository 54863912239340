import React from 'react';
import axios from 'axios';

import { getToken } from '../utils/token';

import copyObject from '../utils/copyObject';

export default class TechniciansDatalist extends React.Component {
  constructor(props) {
    super(props);
    this.state = { technicians: [], options: [] };
  }

  componentDidMount() {
    this.getTechnicians();
  }

  componentDidUpdate() {
    if (this.state.technicians.length > 0 && this.state.options.length === 0) {
      this.generateDatalistOptions();
    }
  }

  getTechnicians = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/equipments/get_technicians/`, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        this.setState({
          technicians: copyObject(response.data),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  generateDatalistOptions = () => {
    this.setState({ options: this.state.technicians.map(this.valueToElement) });
  };

  valueToElement = (value) => {
    return <option key={value} value={value}></option>;
  };

  render() {
    return <datalist id={this.props.id}>{this.state.options}</datalist>;
  }
}
