import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function SidebarButton(props) {
  const lines = props.text.split(';');
  return (
    <Link to={props.url} className='flex flex-col items-center justify-center w-full h-20 py-1 hover:bg-gray-900'>
      <FontAwesomeIcon icon={props.iconName} className='text-3xl' />
      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </Link>
  );
}
