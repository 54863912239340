import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatFractionalValue, isNumeric, removeNonNumericCharacters } from '../utils/formatValues';

export default function SearchComponentsTableRow(props) {
  const component = props.component;
  const rowStyle = 'pl-2 border border-gray-300';
  const criticalAmount = component.min_amount > component.amount;

  document.body.style.cursor = 'auto' //Set default cursor

  let rowColor = props.rowNumber % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100';
  if (criticalAmount) rowColor = 'bg-red-400';

  function updateComponent(field, value) {
    document.body.style.cursor = 'progress' //Set loading cursor
    if (field === 'value') {
      value = Number(removeNonNumericCharacters(value));
    }
    if (typeof value === 'string' || value instanceof String) {
      value = value.toUpperCase();
    }
    
    if (!isValid(field, value)) {
      return;
    }
    if (String(component[field]) === String(value)) {
      return;
    }
    props.updateMethod(component.id, field, value);
  }

  function isValid(field, value) {
    if (field === 'measurement_unit') {
      if (!['UN', 'MT', 'GM'].includes(value)) {
        alert('Unidade deve ser um valor entre "UN", "MT" e "GM"');
        return false;
      }
    }
    if (field === 'value') {
      if (!isNumeric(value)) {
        alert('Valor inválido');
        return false;
      }
    }
    if (field === 'ncm') {
      if (value !== '' && value.length !== 8) {
        alert('NCM deve possui 8 dígitos');
        return false;
      }
    }
    if (['amount', 'min_amount'].includes(field)) {
      if (!isNumeric(value)) {
        alert('Quantidades devem ser números!');
        return false;
      }
    }
    return true;
  }

  return (
    <tr className={`${rowStyle} ${rowColor} ${criticalAmount ? '' : 'hover:bg-white'} uppercase`}>
      <td className={`text-center`}>{component.id}</td>
      <td
        className={`${rowStyle}`}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlur={(e) => updateComponent('description', e.target.innerHTML)}
      >
        {component.description}
      </td>
      <td
        className={`${rowStyle}`}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlur={(e) => updateComponent('ncm', e.target.innerHTML)}
      >
        {component.ncm}
      </td>
      <td
        className={`${rowStyle} uppercase`}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlur={(e) => updateComponent('measurement_unit', e.target.innerHTML)}
      >
        {component.measurement_unit}
      </td>
      <td
        className={`${rowStyle}`}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlur={(e) => updateComponent('min_amount', e.target.innerHTML)}
      >
        {component.min_amount}
      </td>
      <td
        className={`${rowStyle}`}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlur={(e) => updateComponent('amount', e.target.innerHTML)}
      >
        {component.amount}
      </td>
      <td
        className={`${rowStyle}`}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlur={(e) => updateComponent('value', e.target.innerHTML)}
      >{`R$ ${formatFractionalValue(String(component.value))}`}</td>
      <td
        className={`${rowStyle}`}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlur={(e) => updateComponent('provider', e.target.innerHTML)}
        >
        {component.provider}
      </td>
      <td
        className={`${rowStyle} text-center`}
        contentEditable={false}
        suppressContentEditableWarning={true}
      >
        <input 
          type='checkbox' 
          className='w-4 h-4'
          checked={component.should_export} 
          onChange={(e) => {updateComponent('should_export', e.target.checked)}}
        />
      </td>
      <td className='border border-gray-300 px-2 text-center'>
        <button onClick={() => props.displayDeleteModal(component.id)}>
          <FontAwesomeIcon icon='trash' className='mx-3 text-red-500' />
        </button>
      </td>
    </tr>
  );
}
