import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/formatValues';

export default function SearchTableRow(props) {
  const navigate = useNavigate();
  const equipment = props.equipment;
  const rowColor = props.rowNumber % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100';
  const rowStyle = 'pl-2 border border-gray-300';

  let formatted_manufacturing_date = formatDate(equipment.manufacturing_date);

  return (
    <tr
      className={`${rowStyle} ${rowColor} cursor-pointer hover:bg-white`}
      onClick={() => navigate(`/view/?#${equipment.serial}`)}
    >
      <td className={`text-center`}>{props.rowNumber}</td>
      <td className={`${rowStyle}`}>{equipment.serial}</td>
      <td className={`${rowStyle}`}>{equipment.model.name}</td>
      <td className={`${rowStyle}`}>{equipment.NF}</td>
      <td className={`${rowStyle}`}>{equipment.dealer}</td>
      <td className={`${rowStyle}`}>{equipment.client}</td>
      <td className={`${rowStyle}`}>{formatted_manufacturing_date}</td>
      <td className={`${rowStyle}`}>{equipment.technician}</td>
      <td className={`${rowStyle}`}>{equipment.in_stock ? 'SIM' : 'NÃO'}</td>
      <td className={`${rowStyle}`}>{equipment.service_orders.length > 0 ? 'SIM' : 'NÃO'}</td>
    </tr>
  );
}
