import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import { formatValue, formatDate, removeSpecialCharacters } from '../utils/formatValues';

export default function SearchOSTableRow(props) {
  const navigate = useNavigate();
  const os = props.os;
  const rowColor = props.rowNumber % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100';
  const rowStyle = 'pl-2 border border-gray-300';

  let formatted_arrival_date = formatDate(os.arrival_date);
  let formatted_departure_date = formatDate(os.departure_date);

  const labor_value = Number(removeSpecialCharacters(os.labor_value));
  const components_value = Number(removeSpecialCharacters(os.components_value));
  let total_value = String(labor_value + components_value);

  return (
    <tr
      className={`${rowStyle} ${rowColor} cursor-pointer hover:bg-white`}
      onClick={() => navigate(`/view/?#${os.serial}`)}
    >
      <td className={`text-center`}>{os.id}</td>
      <td className={`${rowStyle}`}>{os.serial}</td>
      <td className={`${rowStyle}`}>{formatted_arrival_date}</td>
      <td className={`${rowStyle}`}>
        {moment(formatted_departure_date, 'YYYY/MM/DD').isValid() ? formatted_departure_date : 'NÃO DESPACHADO'}
      </td>
      <td className={`${rowStyle}`}>{os.technician}</td>
      <td className={`${rowStyle}`}>
        {!['', ' ', ','].includes(formatValue(os.components_value)) ? `R$ ${formatValue(os.components_value)}` : '-'}
      </td>
      <td className={`${rowStyle}`}>
        {!['', ' ', ','].includes(formatValue(os.labor_value)) ? `R$ ${formatValue(os.labor_value)}` : '-'}
      </td>
      <td className={`${rowStyle}`}>
        {!['', ' ', ','].includes(formatValue(total_value)) ? `R$ ${formatValue(total_value)}` : '-'}
      </td>
    </tr>
  );
}
