import axios from 'axios';
import React from 'react';
import ReactModal from 'react-modal';

import { getToken } from '../utils/token';
import copyObject from '../utils/copyObject';

import Toast from './Toast';
import FormButton from './FormButton';

export default class NewCompoundComponentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      component: {
        description: '',
        ncm: '',
        measurement_unit: 'UN',
        amount: 0,
        min_amount: 1,
        value: 0,
        provider: '',
        its_compound: true,
      },
      displayToast: false,
      simpleComponents: [],
    };

    this.errorToastData = {
      title: 'Erro',
      color: 'bg-red-700',
      position: 'top_right',
      text: 'Falha ao salvar!',
      icon: 'exclamation-circle',
      click: this.setState.bind(this),
    };

    this.successToastData = {
      title: 'Sucesso',
      color: 'bg-green-600',
      position: 'top_right',
      text: 'Registro salvo!',
      icon: 'check-circle',
      click: this.setState.bind(this),
    };
  }

  componentDidMount() {
    this.getComponents();
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    if (['description', 'provider'].includes(name)) {
      value = value.toUpperCase();
    }

    const newData = copyObject(this.state.component);
    newData[name] = value;

    this.setState({
      component: newData,
    });
  };

  handleSimpleComponentsChange = (event) => {
    const target = event.target;
    const index = target.name;
    let value = target.value;

    if (value < 0) {
      return;
    }

    const newData = copyObject(this.state.simpleComponents);
    newData[index].N = value;

    this.setState({
      simpleComponents: newData,
    });
  };

  validateForm = () => {
    const form = document.querySelector('#form');
    return form.checkValidity();
  };

  async getComponents() {
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/components/?its_compound=0`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    response.data.forEach((element) => {
      element.N = 0;
    });
    this.setState({ simpleComponents: response.data });
  }

  saveComponent = async () => {
    let payload = this.state.component;
    payload.subcomponents = this.state.simpleComponents.filter((cmp) => {
      return Number(cmp.N) > 0;
    });
    axios
      .post(`${process.env.REACT_APP_API_URL}/components/`, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        this.successfulUpdate = true;
        this.setState({
          displayToast: true,
        });
        setTimeout(window.location.reload.bind(window.location), 1000);
      })
      .catch((err) => {
        this.successfulUpdate = false;
        this.setState({ displayToast: true });
      });
  };

  render() {
    const textInputStyle = 'w-full h-9 pl-2 border border-gray-400 rounded-md';
    const formRowStyle = 'mt-4';

    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.hideMethod}
        style={{
          overlay: { backgroundColor: '#0005', overflowY: 'auto' },
          content: {
            maxHeight: '720px',
            height: 'fit-content',
            width: '500px',
            margin: 'auto',
            border: '2px solid #000c',
            backgroundColor: '#f3f4f5',
          },
        }}
      >
        {this.state.displayToast && this.successfulUpdate && <Toast data={this.successToastData} />}
        {this.state.displayToast && !this.successfulUpdate && <Toast data={this.errorToastData} />}
        <div className='flex justify-between text-2xl font-bold'>
          <h2>Novo componente</h2>
          <button className='font-bold text-gray-600' onClick={this.props.hideMethod}>
            X
          </button>
        </div>
        <div className='flex text-2xl font-bolder'>
          <form
            id='form'
            autoComplete='off'
            className='flex-col text-base'
            onSubmit={(event) => {
              event.preventDefault();
              return false;
            }}
          >
            <div className={`${formRowStyle}`}>
              <label htmlFor='description'>Descrição</label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='description'
                value={this.state.component.description}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formRowStyle}`}>
              <label htmlFor='ncm'>NCM</label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='ncm'
                maxLength={8}
                value={this.state.component.ncm}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formRowStyle}`}>
              <label htmlFor='measurement_unit'>Unidade de medida</label>
              <select
                className={`${textInputStyle} bg-white`}
                name='measurement_unit'
                value={this.state.component.measurement_unit}
                onChange={this.handleInputChange}
              >
                <option value='UN'>Unidade</option>
                <option value='MT'>Metro</option>
                <option value='GM'>Grama</option>
              </select>
            </div>
            <div className={`${formRowStyle}`}>
              <label htmlFor='amount'>Quantidade</label>
              <input
                className={`${textInputStyle}`}
                type='number'
                name='amount'
                value={this.state.component.amount}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formRowStyle}`}>
              <label htmlFor='min_amount'>Quantidade mínima</label>
              <input
                className={`${textInputStyle}`}
                type='number'
                name='min_amount'
                value={this.state.component.min_amount}
                onChange={this.handleInputChange}
              />
            </div>
            <div className={`${formRowStyle}`}>
              <div className='flex justify-between'>
                <p>Sub-componente</p>
                <p>Quantidade</p>
              </div>
              <fieldset className='w-full border rounded-md overflow-hidden border-gray-400 bg-blue-400'>
                {this.state.simpleComponents.map((cmp, index) => (
                  <div
                    className={`py-1 flex items-center justify-between overflow-none ${
                      cmp.N > 0 ? 'bg-blue-400' : 'bg-white'
                    }`}
                    key={index}
                  >
                    <label htmlFor={index} className='flex-1 pl-3'>
                      {cmp.description.substring(0, 40)}
                    </label>
                    <input
                      name={index}
                      type='number'
                      value={cmp.N}
                      className='w-1/6 bg-transparent outline-none text-center'
                      onChange={this.handleSimpleComponentsChange}
                    ></input>
                  </div>
                ))}
              </fieldset>
            </div>
          </form>
        </div>
        <div className='flex justify-between mt-6 mb-2'>
          <FormButton click={this.props.hideMethod} color='red-500' width='w-32' text='Cancelar' icon='ban' />
          <FormButton
            click={() => {
              if (this.validateForm()) this.saveComponent();
            }}
            type='submit'
            color='green-500'
            width='w-32'
            text='Cadastrar'
            icon='save'
          />
        </div>
      </ReactModal>
    );
  }
}
