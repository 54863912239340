import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatFractionalValue, isNumeric, removeNonNumericCharacters } from '../utils/formatValues';

import EditCompoundComponentModal from './EditCompoundComponentModal';
import InsertCompoundComponentModal from './InsertCompoundComponentModal';

export default class SearchCompoundComponentsTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEditModal: false,
      displayInsertModal: false,
    };
  }

  updateComponent(field, value) {
    if (field === 'value') {
      value = Number(removeNonNumericCharacters(value));
    }
    if (typeof value === 'string' || value instanceof String) {
      value = value.toUpperCase();
    }

    if (!this.isValid(field, value)) {
      return;
    }
    if (String(this.props.component[field]) === String(value)) {
      return;
    }
    this.props.updateMethod(this.props.component.id, field, value);
  }

  isValid(field, value) {
    if (field === 'measurement_unit') {
      if (!['UN', 'MT', 'GM'].includes(value)) {
        alert('Unidade deve ser um valor entre "UN", "MT" e "GM"');
        return false;
      }
    }
    if (field === 'value') {
      if (!isNumeric(value)) {
        alert('Valor inválido');
        return false;
      }
    }
    if (field === 'ncm') {
      if (value !== '' && value.length !== 8) {
        alert('NCM deve possui 8 dígitos');
        return false;
      }
    }
    if (['amount', 'min_amount'].includes(field)) {
      if (!isNumeric(value)) {
        alert('Quantidades devem ser números!');
        return false;
      }
    }
    return true;
  }

  render() {
    this.criticalAmount = this.props.component.min_amount > this.props.component.amount;

    this.rowColor = this.props.rowNumber % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100';
    if (this.criticalAmount) this.rowColor = 'bg-red-400';

    this.rowStyle = 'pl-2 border border-gray-300';

    return (
      <tr
        key={this.props.component.amount}
        className={`${this.rowStyle} ${this.rowColor} ${this.criticalAmount ? '' : 'hover:bg-white'} uppercase`}
      >
        <InsertCompoundComponentModal
          isOpen={this.state.displayInsertModal}
          component={this.props.component}
          hideMethod={() => {
            this.setState({ displayInsertModal: false });
          }}
        />
        {this.props.simpleComponents.length > 0 && (
          <EditCompoundComponentModal
            isOpen={this.state.displayEditModal}
            hideMethod={() => {
              this.setState({ displayEditModal: false });
            }}
            component={this.props.component}
            componentsList={this.props.componentsList}
            simpleComponents={this.props.simpleComponents}
          />
        )}
        <td className={`text-center`}>{this.props.component.id}</td>
        <td
          className={`${this.rowStyle}`}
          contentEditable={true}
          suppressContentEditableWarning={true}
          onBlur={(e) => this.updateComponent('description', e.target.innerHTML)}
        >
          {this.props.component.description}
        </td>
        <td
          className={`${this.rowStyle}`}
          contentEditable={true}
          suppressContentEditableWarning={true}
          onBlur={(e) => this.updateComponent('ncm', e.target.innerHTML)}
        >
          {this.props.component.ncm}
        </td>
        <td
          className={`${this.rowStyle} uppercase`}
          contentEditable={true}
          suppressContentEditableWarning={true}
          onBlur={(e) => this.updateComponent('measurement_unit', e.target.innerHTML)}
        >
          {this.props.component.measurement_unit}
        </td>
        <td
          className={`${this.rowStyle}`}
          contentEditable={true}
          suppressContentEditableWarning={true}
          onBlur={(e) => this.updateComponent('min_amount', e.target.innerHTML)}
        >
          {this.props.component.min_amount}
        </td>
        <td
          className={`${this.rowStyle}`}
          contentEditable={true}
          suppressContentEditableWarning={true}
          onBlur={(e) => this.updateComponent('amount', e.target.innerHTML)}
        >
          {this.props.component.amount}
        </td>
        <td
          className={`${this.rowStyle}`}
          contentEditable={true}
          suppressContentEditableWarning={true}
          onBlur={(e) => this.updateComponent('value', e.target.innerHTML)}
        >{`R$ ${formatFractionalValue(String(this.props.component.value))}`}</td>
        <td className='border border-gray-300 px-2 text-center'>
          <button
            onClick={() => {
              this.setState({ displayInsertModal: true });
            }}
          >
            <FontAwesomeIcon icon='plus' className='mx-3 text-green-600' />
          </button>
        </td>
        <td className='border border-gray-300 px-2 text-center'>
          <button
            onClick={() => {
              this.setState({ displayEditModal: true });
            }}
          >
            <FontAwesomeIcon icon='list' className='mx-3 text-blue-500' />
          </button>
        </td>
        <td className='border border-gray-300 px-2 text-center'>
          <button onClick={() => this.props.displayDeleteModal(this.props.component.id)}>
            <FontAwesomeIcon icon='trash' className='mx-3 text-red-500' />
          </button>
        </td>
      </tr>
    );
  }
}
