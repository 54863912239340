import axios from 'axios';
import React from 'react';

import { getToken } from '../utils/token';
import { SyncLoader } from 'react-spinners';
import EquipmentSheet from '../components/EquipmentSheet';

export default class ViewPage extends React.Component {
  constructor() {
    super();
    this.serial = this.getURLSerial();
    this.state = { equipmentData: {} };
  }

  getURLSerial() {
    return window.location.hash.substring(1);
  }

  componentDidMount() {
    this.getEquipmentData();
  }

  getEquipmentData = async () => {
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/equipments/?serial=${this.serial}`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    this.setState({ equipmentData: response.data[0] });
  };

  render() {
    return (
      <div className='flex justify-center items-center py-5 h-auto w-full bg-secondary'>
        {Object.keys(this.state.equipmentData).length !== 0 && (
          <EquipmentSheet equipmentData={this.state.equipmentData} />
        )}
        <SyncLoader loading={Object.keys(this.state.equipmentData).length === 0} />
      </div>
    );
  }
}
