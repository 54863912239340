import axios from 'axios';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getToken } from '../utils/token';
import SearchTable from '../components/SearchTable';

export default class StockPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      equipments: [],
    };
  }

  componentDidMount() {
    this.getEquipments();
  }

  startPreRequestTimeout = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.getEquipments.bind(this), 1000);
  };

  async getEquipments() {
    this.setState({ loading: true });
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/equipments/?only_in_stock`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    this.setState({ equipments: response.data, loading: false });
  }

  render() {
    return (
      <div className='inline-block h-screen overflow-x-hidden overflow-y-scroll w-full bg-secondary'>
        <nav className='w-full h-20 flex sticky top-0 items-center justify-center bg-secondary'>
          <h1 className='text-2xl font-bold text-primary'>Equipamentos em estoque</h1>
        </nav>
        <SearchTable
          headerWords={[
            'Nº',
            'Serial',
            'Modelo',
            'NF',
            'Revendedor',
            'Cliente',
            'Fabricação',
            'Técnico',
            <FontAwesomeIcon icon='boxes' />,
            <FontAwesomeIcon icon='tools' />,
          ]}
          equipments={this.state.equipments}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
