import React from 'react';
import axios from 'axios';

import Toast from '../components/Toast';

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      displayToast: false,
    };

    this.errorToast = (
      <Toast
        data={{
          position: 'top_right',
          color: 'bg-red-700',
          title: 'Erro',
          text: 'Usuário ou senha inválidos',
          icon: 'exclamation-circle',
          click: this.setState.bind(this),
        }}
      />
    );
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const request_obj = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/api-token-auth/`,
      data: {
        username: this.state.username,
        password: this.state.password,
      },
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api-token-auth/`, request_obj.data)
      .then((res) => {
        this.props.setToken(res.data.token);
        this.props.navigate('search/');
      })
      .catch((err) => {
        this.setState({
          username: '',
          password: '',
          displayToast: true,
        });
      });
  };

  render() {
    return (
      <div className='w-screen h-screen flex flex-col items-center justify-center bg-gray-600'>
        <div className='w-1/2 h-auto max-w-screen-sm flex flex-col items-center shadow-xl rounded-lg bg-gray-100'>
          <h1 className='my-10 text-3xl text-blue-800 font-medium'>Sistema GW</h1>
          <hr className='w-9/12 border-gray-300' />
          <form className='w-9/12 mt-8 text-xl text-blue-700 font-medium' onSubmit={this.handleSubmit}>
            <div className='flex flex-col'>
              <label htmlFor='user'>Usuário</label>
              <input
                type='text'
                id='user'
                value={this.state.username}
                className='h-10 mt-2 px-2 border rounded text-black border-gray-300'
                onInput={(e) => this.setState({ username: e.target.value })}
                autoFocus
              />
            </div>
            <div className='flex flex-col mt-6'>
              <label htmlFor='password'>Senha</label>
              <input
                type='password'
                id='password'
                value={this.state.password}
                className='h-10 mt-2 px-2 border rounded text-black border-gray-300'
                onInput={(e) => this.setState({ password: e.target.value })}
              />
            </div>
            <button type='submit' className='mt-8 mb-10 p-2 rounded-md text-white bg-blue-700 hover:bg-blue-800'>
              Entrar
            </button>
          </form>
        </div>
        {this.state.displayToast && this.errorToast}
      </div>
    );
  }
}
