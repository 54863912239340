import React from 'react';
import ReactModal from 'react-modal';

import { getToken } from '../utils/token';

import FormButton from './FormButton';

export default function StockReportModal(props) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onRequestClose={props.hideMethod}
      style={{
        overlay: { backgroundColor: '#0005' },
        content: {
          height: '250px',
          width: '300px',
          margin: 'auto',
          border: '2px solid #000c',
          backgroundColor: '#f3f4f5',
        },
      }}
    >
      <div className='flex justify-between text-2xl font-bold'>
        <h2>Escolha uma data</h2>
        <button className='font-bold text-gray-600' onClick={props.hideMethod}>
          X
        </button>
      </div>
      <div className='flex justify-center h-10 mt-12 text-2xl font-bolder'>
          <input 
            type='date' 
            name='date' 
            defaultValue={new Date((new Date()).valueOf() - 1000*60*60*24).toLocaleDateString('en-CA')}
            className='w-full h-9 pl-2 border border-gray-400 rounded-md'>
          </input>
      </div>
      <div className='flex justify-end mt-10'>
        <FormButton click={() => {
            const input = document.getElementsByName('date')[0];
            getReport(input.value);
          }} color='green-500' width='w-28' text='Gerar' icon='cog' 
        />
      </div>
    </ReactModal>
  );
}

async function getReport(date) {
  await fetch(`${process.env.REACT_APP_API_URL}/stock-reports/?date=${date}`, {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  })
  .then(response => response.blob())
  .then(blob => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `estoque_${date}.xlsx`;
    link.click();
  })
  .catch((err) => {
    console.log(err);
  });
}