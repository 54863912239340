import { SyncLoader } from 'react-spinners';
import SearchOSTableRow from './SearchOSTableRow';

export default function SearchOSTable(props) {
  const headStyle = 'pl-2';

  return (
    <table className='w-full bg-primary'>
      <thead className='text-white h-10'>
        <tr className='text-left'>
          {props.headerWords.map((word) => (
            <th key={word} className={headStyle}>
              {word}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!props.loading &&
          props.serviceOrders.map((os, index) => <SearchOSTableRow key={os.id} os={os} rowNumber={index + 1} />)}
        {props.loading && (
          <tr>
            <td colSpan={props.headerWords.length} className='bg-secondary'>
              <div className='h-screen flex justify-center mt-80'>
                <SyncLoader loading={true} color={'#000'} />
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
