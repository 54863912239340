import React from 'react';
import axios from 'axios';

import { getToken } from '../utils/token';
import copyObject from '../utils/copyObject';

import {
  formatCPF,
  formatValue,
  formatSerial,
  formatPhoneNumber,
  removeSpecialCharacters,
} from '../utils/formatValues';

import Toast from './Toast';
import OSModal from './OSModal';
import TagsModal from './TagsModal';
import NewOSModal from './NewOSModal';
import FormButton from './FormButton';
import DeleteModal from './DeleteModal';
import TechniciansDatalist from './TechniciansDatalist';

export default class EquipmentSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OSToShow: null,
      editingMode: false,
      displayToast: false,
      displayOSModal: false,
      displayTagsModal: false,
      displayNewOSModal: false,
      displayDeleteModal: false,
      equipmentData: copyObject(props.equipmentData),
      equipmentDataCopy: copyObject(props.equipmentData),
    };

    this.errorToastData = {
      title: 'Erro',
      color: 'bg-red-700',
      position: 'top_right',
      text: 'Falha ao atualizar!',
      icon: 'exclamation-circle',
      click: this.setState.bind(this),
    };

    this.successToastData = {
      title: 'Sucesso',
      color: 'bg-green-600',
      position: 'top_right',
      text: 'Registro atualizado!',
      icon: 'check-circle',
      click: this.setState.bind(this),
    };
  }

  hideOSModal = () => {
    this.setState({ displayOSModal: false });
  };

  displayNewOSModal = () => {
    this.setState({ displayNewOSModal: true });
  };

  hideNewOSModal = () => {
    this.setState({ displayNewOSModal: false });
  };

  displayTagsModal = () => {
    this.setState({ displayTagsModal: true });
  };

  hideTagsModal = () => {
    this.setState({ displayTagsModal: false });
  };

  displayDeleteModal = () => {
    this.setState({ displayDeleteModal: true });
  };

  hideDeleteModal = () => {
    this.setState({ displayDeleteModal: false });
  };

  startEditing = () => {
    this.setState({ editingMode: true });
  };

  saveEditing = async () => {
    this.setState({ editingMode: false });

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/equipments/${this.state.equipmentData.serial}/`,
        copyObject(this.state.equipmentData),
        {
          headers: {
            Authorization: `Token ${getToken()}`,
          },
        }
      )
      .then((response) => {
        this.successfulUpdate = true;
        this.setState({
          displayToast: true,
          equipmentData: copyObject(response.data),
          equipmentDataCopy: copyObject(response.data),
        });
      })
      .catch((err) => {
        this.successfulUpdate = false;
        this.restoreOriginalEquipmentData();
        this.setState({ displayToast: true });
      });
  };

  cancelEdit = () => {
    this.setState({ editingMode: false });
    this.restoreOriginalEquipmentData();
  };

  deleteEquipment = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/equipments/${this.state.equipmentData.serial}/`, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        window.location.assign('./../search');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  restoreOriginalEquipmentData = () => {
    this.setState({ equipmentData: copyObject(this.state.equipmentDataCopy) });
  };

  handleOSButtonClick = (event) => {
    const target = event.target;
    this.setState({ OSToShow: this.state.equipmentData.service_orders[target.id], displayOSModal: true });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value.toUpperCase();

    if (['value', 'client_phone'].includes(name)) {
      value = removeSpecialCharacters(value);
    }

    if (name === 'modelo') {
      this.getSerial(value);
    }

    if (name === 'value') {
      value = String(Number(value));
    }

    const newData = copyObject(this.state.equipmentData);
    newData[name] = value;

    this.setState({
      equipmentData: newData,
    });
  };

  handleCheckboxChanges = (event) => {
    const target = event.target;
    const name = target.name;

    const newData = copyObject(this.state.equipmentData);
    newData[name] = target.checked;

    this.setState({
      equipmentData: newData,
    });
  };

  render() {
    const formGroupStyle = 'flex-col ml-3';
    const formRowStyle = 'flex mt-3 mr-3 justify-evenly';
    const textInputStyle = 'w-full h-8 pl-2 border rounded-md';

    return (
      <div className='w-4/5 max-w-6xl border-2 rounded border-primary' id='view'>
        <OSModal
          isOpen={this.state.displayOSModal}
          OSToShow={this.state.OSToShow}
          hideMethod={this.hideOSModal}
          key={this.state.OSToShow}
        />

        <NewOSModal
          serial={this.state.equipmentData.serial}
          isOpen={this.state.displayNewOSModal}
          hideMethod={this.hideNewOSModal}
        />

        <TagsModal
          equipmentsData={[this.state.equipmentData]}
          isOpen={this.state.displayTagsModal}
          hideMethod={this.hideTagsModal}
        />

        <DeleteModal
          isOpen={this.state.displayDeleteModal}
          hideMethod={this.hideDeleteModal}
          delMethod={this.deleteEquipment}
        />

        {this.state.displayToast && this.successfulUpdate && <Toast data={this.successToastData} />}
        {this.state.displayToast && !this.successfulUpdate && <Toast data={this.errorToastData} />}

        <form className='flex-col' autoComplete='off'>
          <h1 className='text-center text-3xl my-6'>FICHA DO EQUIPAMENTO</h1>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/2`}>
              <label className='' htmlFor='model'>
                Modelo
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='model'
                value={this.state.equipmentData.model.name}
                disabled
              />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='serial'>
                Serial
              </label>
              <input
                className={`${textInputStyle}`}
                type='text'
                name='serial'
                value={formatSerial(this.state.equipmentData.serial)}
                disabled
              />
            </div>
            <div className={`${formGroupStyle} w-1/4`}>
              <label className='' htmlFor='NF'>
                NF
              </label>
              <input
                className={`${textInputStyle} editable`}
                type='number'
                name='NF'
                value={this.state.equipmentData.NF}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='dealer'>
                Revendedor
              </label>
              <input
                className={`${textInputStyle} editable`}
                type='text'
                name='dealer'
                value={this.state.equipmentData.dealer}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
            </div>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='buyer'>
                Comprador
              </label>
              <input
                className={`${textInputStyle} editable`}
                type='text'
                name='buyer'
                value={this.state.equipmentData.buyer}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
            </div>
            <div className={`${formGroupStyle} w-3/12`}>
              <label className='' htmlFor='value'>
                Valor
              </label>
              <input
                className={`${textInputStyle} editable`}
                type='text'
                name='value'
                required={true}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
                value={formatValue(this.state.equipmentData.value)}
              />
            </div>
            <div className={`flex-col ml-3 w-1/12`}>
              <label className='ml-2' htmlFor='model'>
                Estoque
              </label>
              <input
                type='checkbox'
                name='in_stock'
                className={`${textInputStyle}`}
                disabled={!this.state.editingMode}
                onChange={this.handleCheckboxChanges}
                checked={this.state.equipmentData.in_stock}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='client'>
                Cliente final
              </label>
              <input
                className={`${textInputStyle} editable`}
                type='text'
                name='client'
                value={this.state.equipmentData.client}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
            </div>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='CPF'>
                CPF
              </label>
              <input
                className={`${textInputStyle} editable`}
                type='text'
                name='CPF'
                value={formatCPF(this.state.equipmentData.CPF)}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
            </div>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='client_phone'>
                Telefone
              </label>
              <input
                className={`${textInputStyle} editable`}
                type='text'
                name='client_phone'
                value={formatPhoneNumber(this.state.equipmentData.client_phone)}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='manufacturing_date'>
                Data de fabricação
              </label>
              <input
                className={`${textInputStyle} editable`}
                type='date'
                name='manufacturing_date'
                value={this.state.equipmentData.manufacturing_date}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
            </div>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='start_of_warranty'>
                Início da garantia
              </label>
              <input
                className={`${textInputStyle} editable`}
                type='date'
                name='start_of_warranty'
                value={this.state.equipmentData.start_of_warranty}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
            </div>
            <div className={`${formGroupStyle} w-1/3`}>
              <label className='' htmlFor='technician'>
                Técnico responsável
              </label>
              <input
                required
                className={`${textInputStyle} editable`}
                type='text'
                name='technician'
                list='technicians-list'
                value={this.state.equipmentData.technician}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
              <TechniciansDatalist field='technician' id='technicians-list' />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-full`}>
              <label className='' htmlFor='comments'>
                Comentários
              </label>
              <textarea
                className='w-full pl-2 border rounded-md editable'
                name='comments'
                rows='4'
                value={this.state.equipmentData.comments}
                onChange={this.handleInputChange}
                disabled={!this.state.editingMode}
              />
            </div>
          </div>
          <div className={`${formRowStyle}`}>
            <div className={`${formGroupStyle} w-full`}>
              <label className='' htmlFor='service_orders'>
                Histórico de manutenções
              </label>
              <div id='service_orders' className='mt-3 mb-1'>
                {this.state.equipmentData.service_orders.map((order, index) => (
                  <button
                    type='button'
                    id={index}
                    key={index}
                    onClick={this.handleOSButtonClick}
                    className='w-full h-10 border border-gray-200 hover:bg-gray-200 rounded-sm bg-gray-100'
                  >
                    OS-{order.id}
                  </button>
                ))}
                {!this.state.equipmentData.service_orders.length && (
                  <div className='w-full h-10 border flex justify-center items-center border-gray-200 rounded-sm bg-gray-100'>
                    Nenhuma O.S. registrada
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='flex mt-3 mb-3 ml-3 justify-between'>
            <div>
              {!this.state.editingMode && (
                <FormButton click={this.startEditing} color='yellow-500' width='w-28' text='Editar' icon='edit' />
              )}
              {!this.state.editingMode && (
                <FormButton
                  click={this.displayTagsModal}
                  color='blue-500'
                  width='w-44'
                  text='Gerar etiqueta'
                  icon='ticket-alt'
                />
              )}
              {!this.state.editingMode && (
                <FormButton click={this.displayNewOSModal} color='gray-600' width='w-36' text='Nova O.S.' icon='cog' />
              )}
              {this.state.editingMode && (
                <FormButton click={this.cancelEdit} color='red-500' width='w-32' text='Cancelar' icon='ban' />
              )}
              {this.state.editingMode && (
                <FormButton click={this.saveEditing} color='green-500' width='w-28' text='Salvar' icon='save' />
              )}
            </div>
            {!this.state.editingMode && (
              <FormButton
                click={this.displayDeleteModal}
                custom_style='mr-4'
                color='red-500'
                width='w-28'
                text='Deletar'
                icon='trash'
              />
            )}
          </div>
        </form>
      </div>
    );
  }
}
