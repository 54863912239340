import axios from 'axios';
import React from 'react';
import ReactModal from 'react-modal';

import { getToken } from '../utils/token';

import FormButton from './FormButton';

export default class InsertCompoundComponentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfNewRegisters: 1,
    };
  }

  updateComponent = async () => {
    const payload = { number_of_new_registers: this.state.numberOfNewRegisters };
    axios
      .patch(`${process.env.REACT_APP_API_URL}/components/${this.props.component.id}/update_inventory/`, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
        },
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 409) {
          alert(`Erro!\nComponente insuficiente: ${err.response.data}`);
        }
      });
  };

  render() {
    const incrementDecrementButtonClasses = 'w-10 h-10 bg-tertiary hover:bg-gray-400';

    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.hideMethod}
        style={{
          overlay: { backgroundColor: '#0005' },
          content: {
            height: '250px',
            width: '550px',
            margin: 'auto',
            border: '2px solid #000c',
          },
        }}
      >
        <div className='flex justify-between text-2xl font-bold'>
          <h2>Quantidade de componentes fabricados:</h2>
          <button className='font-bold text-gray-600' onClick={this.props.hideMethod}>
            X
          </button>
        </div>
        <div className='flex justify-center h-10 mt-12 text-2xl font-bolder'>
          <div className='h-11 fit-content border-2 rounded border-gray-600'>
            <button
              className={incrementDecrementButtonClasses}
              onClick={() => {
                if (this.state.numberOfNewRegisters > 1) {
                  this.setState({ numberOfNewRegisters: this.state.numberOfNewRegisters - 1 });
                }
              }}
            >
              -
            </button>
            <input
              type='text'
              id='tags_modal_input'
              className='w-12 h-10 text-center bg-gray-100'
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value) this.setState({ numberOfNewRegisters: value });
              }}
              value={this.state.numberOfNewRegisters}
            ></input>
            <button
              className={incrementDecrementButtonClasses}
              onClick={() => {
                this.setState({ numberOfNewRegisters: this.state.numberOfNewRegisters + 1 });
              }}
            >
              +
            </button>
          </div>
        </div>
        <div className='flex justify-end mt-10'>
          <FormButton click={this.props.hideMethod} color='red-500' width='w-32' text='Cancelar' icon='ban' />
          <FormButton click={this.updateComponent} color='green-500' width='w-28' text='Cadastrar' icon='cog' />
        </div>
      </ReactModal>
    );
  }
}
