import SidebarButton from './SidebarButton';
import Logo from '../images/Logo.png';

export default function Sidebar() {
  return (
    <div className='inline-block h-auto w-24 text-tertiary'>
      <div className='p-2 mt-2 mb-2'>
        <img src={Logo} alt=''></img>
      </div>
      <div className='flex flex-col items-center text-sm font-bold'>
        <SidebarButton url='/search' iconName='search' text='Pesquisar' />
        <SidebarButton url='/register' iconName='plus' text='Inserir' />
        <SidebarButton url='/service-orders' iconName='tools' text='O.S.' />
        <div className='flex flex-col items-center justify-center w-full'>
          <p>______________</p>
          <p className='mt-4 font-bold text-base'>Estoque</p>
          <p className='mb-2'>______________</p>
          <SidebarButton url='/components' iconName='cog' text='Componentes;simples' />
          <SidebarButton url='/compound-components' iconName='cogs' text='Componentes;compostos' />
          <SidebarButton url='/models' iconName='list' text='Modelos' />
          <SidebarButton url='/inventory' iconName='boxes' text='Equipamentos' />
        </div>
      </div>
    </div>
  );
}
