import { SyncLoader } from 'react-spinners';

import copyObject from '../utils/copyObject';

import SearchModelsTableRow from './SearchModelsTableRow';

export default function SearchComponentsTable(props) {
  const headStyle = 'pl-2';

  let total_value = 0;
  props.models.map((component) => (total_value += component.value * component.amount));

  return (
    <table className='w-full bg-primary'>
      <thead className='text-white h-10'>
        <tr className='text-left'>
          {props.headerWords.map((word, index) => (
            <th key={index} className={headStyle}>
              {word}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!props.loading &&
          props.dataLoaded &&
          props.models.map((model, index) => (
            <SearchModelsTableRow
              model={model}
              key={model.id}
              rowNumber={index + 1}
              components={props.components ? copyObject(props.components) : props.components}
              componentsList={
                props.componentsLists[model.id]
                  ? copyObject(props.componentsLists[model.id])
                  : props.componentsLists[model.id]
              }
            />
          ))}
        {(props.loading || !props.dataLoaded) && (
          <tr>
            <td colSpan={props.headerWords.length} className='bg-secondary'>
              <div className='h-screen flex justify-center mt-80'>
                <SyncLoader loading={true} color={'#000'} />
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
