import axios from 'axios';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchTable from '../components/SearchTable';
import Select from '../components/Select';
import { getToken } from '../utils/token';

export default class SearchPage extends React.Component {
  constructor(props) {
    super(props);

    this.OSFilter = '';
    this.LimitResultsFilter = '&limit_results';

    this.searchText = '';
    this.searchColumn = 'serial';

    this.state = {
      loading: false,
      equipments: [],
    };
  }

  componentDidMount() {
    this.getEquipments();
  }

  handleColumnSelectChanges = (e) => {
    this.searchColumn = e.target.value;
    this.startPreRequestTimeout();
  };

  handleOSSelectChanges = (e) => {
    this.OSFilter = e.target.value;
    this.startPreRequestTimeout();
  };

  handleCheckboxChanges = (e) => {
    const checkbox = e.target;

    this.LimitResultsFilter = '&limit_results';
    if (checkbox.checked) {
      this.LimitResultsFilter = '';
    }

    this.startPreRequestTimeout();
  };

  handleTextInput = (e) => {
    this.searchText = e.target.value.toUpperCase();
    this.startPreRequestTimeout();
  };

  startPreRequestTimeout = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.getEquipments.bind(this), 1000);
  };

  async getEquipments() {
    this.setState({ loading: true });
    const request_obj = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/equipments/?${this.searchColumn}=${this.searchText}${this.OSFilter}${this.LimitResultsFilter}`,
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    };
    const response = await axios(request_obj);
    this.setState({ equipments: response.data, loading: false });
  }

  render() {
    return (
      <div className='inline-block h-screen overflow-x-hidden overflow-y-scroll w-full bg-secondary'>
        <nav className='w-full h-20 flex sticky top-0 items-center justify-center bg-secondary'>
          <div className='h-10 mr-10 border rounded border-gray-300'>
            <Select
              className='h-full w-30 pl-2 outline-none border-r border-gray-300 bg-white'
              optionValues={{
                Serial: 'serial',
                Revendedor: 'dealer',
                Técnico: 'technician',
                Cliente: 'client',
                Modelo: 'modelo',
                NF: 'NF',
              }}
              onChange={this.handleColumnSelectChanges}
            />
            <input
              type='text'
              className='h-9 px-4 uppercase outline-none focus:ring-2 focus:ring-tertiary'
              autoFocus
              onInput={this.handleTextInput}
            />
            <FontAwesomeIcon icon='search' className='mx-3 text-blue-600' />
          </div>
          <div className='h-10 mr-10 border rounded border-gray-300 bg-gray-200'>
            <span className='h-full mx-4'>Apenas registros com manutenções?</span>
            <Select
              className='h-full w-24 pl-2 outline-none border-l border-gray-300 bg-white'
              optionValues={{ Não: '', Sim: '&only_with_OS' }}
              onChange={this.handleOSSelectChanges}
            />
          </div>
          <div className='h-10 flex items-center'>
            <input type='checkbox' id='limit_results' className='w-5 h-5 mr-2' onChange={this.handleCheckboxChanges} />
            <label htmlFor='limit_results'>Até 1000 resultados</label>
          </div>
        </nav>
        <SearchTable
          headerWords={[
            'Nº',
            'Serial',
            'Modelo',
            'NF',
            'Revendedor',
            'Cliente',
            'Fabricação',
            'Técnico',
            <FontAwesomeIcon icon='boxes' />,
            <FontAwesomeIcon icon='tools' />,
          ]}
          equipments={this.state.equipments}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
