import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default class Toast extends React.Component {
  constructor(props) {
    super(props);

    this.positionClasses = {
      top_left: 'top-2 left-2',
      top_right: 'top-2 right-2',
      bottom_left: 'bottom-2 left-2',
      bottom_right: 'bottom-2 right-2',
    };

    this.defaultClasses = `w-72 h-24 fixed flex rounded-md shadow-md text-gray-100 ${this.props.data.color}`;
  }

  componentDidMount() {
    this.setHiddenTimeout();
  }

  setHiddenTimeout() {
    if (this.hiddenTimeout !== null) clearTimeout(this.hiddenTimeout);
    this.hiddenTimeout = setTimeout(
      () => this.props.data.click({ displayToast: false }),
      5000
    );
  }

  render() {
    return (
      <div
        className={`${this.defaultClasses} ${
          this.positionClasses[this.props.data.position]
        }`}
      >
        <div className='w-1/4 flex justify-center items-center text-5xl'>
          <FontAwesomeIcon icon={this.props.data.icon} className='' />
        </div>
        <div className='w-3/4'>
          <div className='h-2/5 mt-2 flex justify-between items-end'>
            <p className='text-2xl font-medium'>{this.props.data.title}</p>
            <button
              className='text-xl font-medium mr-4 mb-2'
              onClick={() => this.props.data.click({ displayToast: false })}
            >
              X
            </button>
          </div>
          <div className='h-3/5 mt-2'>
            <p>{this.props.data.text}</p>
          </div>
        </div>
      </div>
    );
  }
}
