import React from 'react';

import RegisterEquipmentSheet from '../components/RegisterEquipmentSheet';

export default class ViewPage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className='flex justify-center items-center h-auto w-full bg-secondary'>
        <RegisterEquipmentSheet />
      </div>
    );
  }
}
