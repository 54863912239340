import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatValue } from '../utils/formatValues';

import EditModelModal from '../components/EditModelModal';

export default class SearchModelsTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      components: props.components,
      displayModal: false,
    };
  }

  render() {
    const rowStyle = 'pl-2 border border-gray-300';
    const rowColor = this.props.rowNumber % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100';

    return (
      <tr className={`${rowStyle} ${rowColor} uppercase`}>
        {this.state.components.length > 0 && (
          <EditModelModal
            isOpen={this.state.displayModal}
            hideMethod={() => {
              this.setState({ displayModal: false });
            }}
            model={this.props.model}
            components={this.state.components}
            componentsList={this.props.componentsList}
          />
        )}
        <td className={`text-center`}>{this.props.model.id}</td>
        <td className={`${rowStyle}`}>{this.props.model.name}</td>
        <td className={`${rowStyle}`}>{this.props.model.ncm}</td>
        <td className={`${rowStyle} uppercase`}>{this.props.model.category}</td>
        <td className={`${rowStyle} uppercase`}>{'R$ ' + formatValue(this.props.model.value)}</td>
        <td className='border border-gray-300 px-2 text-center'>
          <button
            onClick={() => {
              this.setState({ displayModal: true });
            }}
          >
            <FontAwesomeIcon icon='list' className='mx-3 text-blue-500' />
          </button>
        </td>
      </tr>
    );
  }
}
